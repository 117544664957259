// amazingtunes API requests service

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { HttpClient, HttpErrorResponse, HttpParams, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, EMPTY } from 'rxjs';
import { map, catchError, tap, mergeMap, expand, reduce } from 'rxjs/operators';
import { CropRect } from '@modules/_shared/ui/components/dialog-image-upload/image-cropper/image-cropper.component';
import { UserV2, ActivityFeed, CompactArtist, SubscribedPlan, UserStats, PermalinkValidityResponse, SocialAuthSession, GeoData } from '@models/user-v2';
import { SubscriptionPlans, SubscriptionPayment, SubscriptionPayments, MemberSubscription, FundingSource, Payment, Plan } from '@models/subscriptions-v2';
import { SiteConfiguration, Country, SocialLoginEndpoints, RadioChannel, TermsServices } from '@models/configurations';

import { ExternalVideo, Video, VideoUploadParams, VideoStream, VideoThumbnails } from '@models/video-v2';
import { AirplaysV2, RadioLogEntry } from '@app/models/airplays-v2';
import { GenreV2, GenreStreams, GenreStream } from '@models/genre-v2';
import { CollectionV2 } from '@models/collection-v2';
import { ImageV2, ImageV2Response } from '@models/image-v2';
import { TuneV2, TuneUploadData, TuneStream, TransloaditResponse } from '@app/models/tune-v2';
import { VoiceClip } from '@models/voice-clips';
import { PlaylistV2 } from '@models/playlist-v2';
import { PaymentV2, PaymentVerification } from '@models/payment-v2';
import { ChartV2Response, AnnualCharts } from '@models/chart-v2';
import { ContentReport } from '@models/content-report';
import { UserService } from '@services/user.service';
import { SnackbarService } from '@services/snackbar.service';
import { TimeSlipService } from '@services/time-slip.service';


import { environment } from '@env/environment';

import slug from 'slug';

const BASE_URI: string = environment.api_endpoint_v2;

interface Pagination {
  page?: number;
  page_size?: number;
  total_entries?: number;
  total_pages?: number;
}

@Injectable({
  providedIn: 'root'
})
export class ApiAmazingtunesV2Service {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private userService: UserService,
    private snackbar: SnackbarService,
    private http: HttpClient,
    private timeSlipService: TimeSlipService
  ) { }


  loginV2(credentials: any): Observable<UserV2> {
    // JWT
    // console.log('loginV2 ... POST auth...');
    // POST /auth
    return this.http.post<any>(BASE_URI + '/auth', credentials) // Headers now added in the interceptor
      .pipe(
        tap(resp => {
          // console.log('loginV2 data: ', resp.data.attributes);
          resp.data.attributes.version = 2; // temp v2 flag
          // to avoid complaints in the header during login..
          resp.data.attributes.attributes = {
            permalink: null,
            // image_urls: { small: '/assets/placeholders/placeholder-square-300.jpg' }
          };
          this.userService.set(resp.data.attributes); // Save initial tokens to User object in localStorage.
          // console.log('... then mergemap verifyUserV2... ');
        }),
        mergeMap(resp => this.verifyUserV2()), // Use mergeMap to do the next request ....
        catchError((error: HttpErrorResponse) => {

          let error_message = error.message;
          if (error.error && error.error.errors) {
            error_message = error.error.errors[0].title;
          }
          this.userService.clear();

          this.snackbar.show(error_message);
          return this.handleError(error);
        })
      );
  }


  logout() {
    localStorage.removeItem('user');
  }

  passwordResetLink(email: string) {
    // POST Send password reset token link to user's email
    return this.http.post<any>(BASE_URI + '/password/reset', { email: email }).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    )
  }

  changePassword(reset_token: string, password: string) {
    // PATCH Change password
    // console.log('Change password: ', password, reset_token);
    return this.http.patch<any>(BASE_URI + '/password/reset', { reset_token: reset_token, password: password }).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    )
  }


  resendConfirmation(email: string) {
    return this.http.post<any>(BASE_URI + '/confirm/resend', { email: email }).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    )
  }

  confirmationToken(token: string): Observable<UserV2> {
    return this.http.post<any>(BASE_URI + '/confirm', { data: { id: token, type: 'confirmation_token' } })
      .pipe(
        tap(response => {
          // console.log('Confirmation response: ', response);
          let user: UserV2 = response.data;
          // JWT in included data
          let access_token: string = response.included[0].attributes.token;
          user.token = access_token;
          user.expires_at = response.included[0].attributes.expires_at;
          user.category = response.included[0].attributes.category;
          this.userService.set(user);
        }),
        mergeMap(data => this.verifyUserV2()),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      )
  }

  verifyUserV2(skip_loading: boolean = false): Observable<UserV2> {
    // console.log('verifyUserV2.. /me ... ');
    return this.http.get<any>(BASE_URI + '/me')
      .pipe(
        mergeMap(resp => this.getProfileDataV2(resp.data, true, skip_loading)), // Use mergeMap to do the next request which then retur the Observable back to the Userservice.login().. then back to the Login component.
        catchError((error: HttpErrorResponse) => {
          let error_message = error.message;
          if (error.error && error.error.errors) {
            error_message = error.error.errors[0].title;
          }
          this.userService.clearAndUpdate();

          this.snackbar.show(error_message);
          return this.handleError(error);
        })
      );
  }

  verifyToken(access_token: string, skip_loading?: boolean): Observable<any> {
    // console.log('verifyToken:', access_token);
    // Temporarily pre-set a localstorage user object with just a token so the interceptor can add it.
    if (typeof window !== 'undefined') {
      // console.log('setting initial token to user item');
      localStorage.setItem('user', JSON.stringify({ token: access_token }));
    }

    let params_data = new HttpParams();
    if (skip_loading) {
      params_data = params_data.set('skip_loading', 'true');
    }
    return this.http.get<any>(BASE_URI + '/me', { params: params_data })
      .pipe(
        // tap(resp => {
        //   //console.log('verifyToken /me data: ', resp.data);
        //   //console.log('now getProfileDataV2 ... ');
        // }),
        mergeMap(resp => this.getProfileDataV2(resp.data, true)), // Use mergeMap to do the next request which then retur the Observable back to the Userservice.login().. then back to the Login component.
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  getUserTermsAgreements(): Observable<TermsServices> {
    return this.http.get<any>(BASE_URI + '/terms/services').pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  setUserTermsAgreements(data: TermsServices): Observable<TermsServices> {
    console.log('set terms prefs:', data);
    return this.http.post<any>(BASE_URI + '/terms/services', data).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );

  }

  getSocialLoginEndpoints(): Observable<SocialLoginEndpoints> {
    return this.http.get<any>(BASE_URI + '/auth/social').pipe(
      map(data => {
        return data.data;
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  // Gets whatever data is possible from a social media account sign up to link an account for social logins.
  getSocialRegistrationData(reg_session_id: string): Observable<SocialAuthSession> {
    let params_data = new HttpParams();
    params_data = params_data.set('reg_session_id', reg_session_id);
    return this.http.get<any>(BASE_URI + '/auth/social/session', { params: params_data }).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  // V2 method
  getProfileDataV2(user: UserV2, me?: boolean, skip_loading: boolean = false): Observable<UserV2> {
    // console.log('getProfileDataV2: me:', me);
    // GET /users/[permalink].json
    let params_data = new HttpParams();
    params_data = params_data.set('include[]', 'user_stats');
    params_data = params_data.append('include[]', 'user_private');
    params_data = params_data.append('include[]', 'image');
    params_data = params_data.append('include[]', 'header_image');

    if (environment.has_subscriptions) {
      params_data = params_data.append('include[]', 'subscription');
    }

    if (skip_loading) {
      params_data = params_data.set('skip_loading', 'true');
    }

    switch (user.attributes.classification) {
      case 'fan':
        break;
      case 'artist':
      case 'user':
        break;
      case 'label':
        if (!me) { // should speed up label logins, as when coming via the /me mergeMap, we should't need this extra data.
          params_data = params_data.append('include[]', 'artists');
          params_data = params_data.append('include[]', 'artists.user_stats');
        }
        break;
      default:
    }
    // console.log('getProfileDataV2 params: ', params_data);
    return this.http.get<any>(BASE_URI + '/users/' + user.attributes.permalink, { params: params_data })
      .pipe(
        tap(resp => {

          let user_private = {};
          let user_stats: UserStats;
          let _image = {};
          let _header_image: any = {};
          let _subscriptions = [];
          let label_user_stats = [];
          let tunes = [];
          let collections = [];
          let artists = [];
          resp.included.forEach(inc => {
            inc.attributes.id = inc.id;
            // Sort through the included data arrays
            switch (inc.type) {
              case 'collection':
                inc.tunes = [];
                inc.artist = {};
                //console.log('COLLECTION ID: ', inc.id);
                collections.push(inc);
                break;
              case 'tune':
                //console.log('TUNE ID: ', inc.id);
                tunes.push(inc);
                break;
              case 'user':
                artists.push(inc);
                break;
              case 'image':
                if (inc.attributes.classification === 'user_image') {
                  _image = inc.attributes; // Used for logged-in user image management.
                  // console.log('%cPROFILE IMAGE', 'color:lime', _image);  
                } else if (inc.attributes.classification === 'user_header_image') {
                  _header_image = inc.attributes; // Used for logged-in user image management.
                  // console.log('%cHEADER IMAGE', 'color:hotpink', _header_image);
                }
                break;
              case 'user_private':
                user_private = inc.attributes;
                break;
              case 'subscription':
                _subscriptions.push(inc);
                break;
              case 'user_stats':
                if (user.attributes.classification === 'label') {
                  if (user.id === inc.attributes.id) {
                    // console.log('user_stats for the label itself : ', inc.attributes.id);
                    user_stats = inc.attributes;
                    if (resp.data.attributes.classification === 'artist') {
                      // add up the collections for simplity in the templates. We bundle eps, singles and albums together as 'collections' in the profile UI tabs.
                      user_stats.upload_counts.collections = user_stats.upload_counts.singles + user_stats.upload_counts.eps + user_stats.upload_counts.albums;
                    }
                  } else {
                    label_user_stats.push(inc.attributes);
                  }
                } else {
                  user_stats = inc.attributes;
                }
                break;
              default:
            }
          });
          // Now get the tune_stats tune_private data and inject them into the tunes array...
          resp.included.forEach(inc => {
            switch (inc.type) {
              case 'user_stats':
                // label artists user_stats
                artists.forEach(artist => {
                  if (inc.id === artist.id) {
                    artist.user_stats = inc.attributes;
                    // add up the collections for simplity in the templates. We bundle eps, singles and albums together as 'collections' in the profile UI tabs.
                    artist.user_stats.upload_counts.collections = artist.user_stats.upload_counts.singles + artist.user_stats.upload_counts.eps + artist.user_stats.upload_counts.albums;
                  }
                });
                break;
              case 'tune_stats':
                tunes.forEach(tune => {
                  if (inc.id === tune.id) {
                    tune._stats = inc.attributes;
                  }
                });
                break;
              case 'tune_private':
                tunes.forEach(tune => {
                  if (inc.id === tune.id) {
                    tune._private = inc.attributes;
                  }
                });
                break;
              default:
            }
          });
          resp.data.user_stats = user_stats;

          user = resp.data;
          if (_subscriptions.length > 0) {
            user._subscriptions = _subscriptions;
            user = this.setHasCredits(user);
          }

          user._image = _image;
          if (_header_image) {
            user._header_image = _header_image;
            user.attributes.header_image_urls = _header_image.urls;
          }

          user.user_private = user_private;

          if (user.attributes.classification === 'fan' && user.user_private.self_classification === null) {
            user.user_private.self_classification = 'fan';
          }
          user.list_grid = false; // stored for list grid/row view preference.
          if (me) {
            // console.log('update full local user data after merged /me > getProfileDataV2() request: ', user);
            // Save merged data to localStorage for current user request (after login)
            this.userService.mergeUserData(user);
            // emits user to subscribers (header).
            //  this.userService.userSub.next(user);
          }
        }),
        map(resp => {
          user = this.setHasCredits(user);
          // console.log('getProfileDataV2 : returning user: ', user);
          return user;
        }),
        catchError((error: HttpErrorResponse) => {
          let error_message = error.message;
          if (error.error && error.error.errors) {
            error_message = error.error.errors[0].title;
          }
          this.userService.clear();
          this.snackbar.show(error_message);
          return this.handleError(error);
        })
      );
  }

  getGenres(parent_id?: number): Observable<GenreV2[]> {
    let endpoint = '/genres';
    if (parent_id) {
      // console.log('GET SUB GENRES of: ', parent_id);
      endpoint += '/' + parent_id;
    }
    return this.http.get<any>(BASE_URI + endpoint)
      .pipe(
        map(data => {
          let _genres: GenreV2[] = [];
          data.data.forEach(genre => {
            _genres.push({
              id: parseInt(genre.id),
              name: genre.attributes.name,
              // Add a slug to create a route for /store/:genre_slug
              slug: slug(genre.attributes.name.split('/').join('-'), { replacement: '-' })
            });
            // console.log( slug(genre.attributes.name, {replacement:'-'}) );
          });
          return _genres;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  getUserV2(permalink: string, skip_loading: boolean = true): Observable<UserV2> {
    // console.log('getUserV2.... ', permalink);
    let params_data = new HttpParams();
    params_data = params_data.set('include[]', 'user_stats');
    params_data = params_data.append('include[]', 'manager');
    params_data = params_data.append('include[]', 'user_private');
    params_data = params_data.append('include[]', 'subscription');
    params_data = params_data.append('include[]', 'header_image');
    if (skip_loading) {
      params_data = params_data.set('skip_loading', 'true');
    }
    return this.http.get<any>(BASE_URI + '/users/' + permalink, { params: params_data })
      .pipe(
        map(data => {
          // Sort through the included.
          let user: UserV2 = data.data;
          let user_stats: UserStats;
          let label_user_stats = [];
          let user_subs = [];
          data.included.forEach(inc => {

            if (inc.type === 'image' && inc?.attributes?.classification === 'user_header_image') {
              // console.log('GOT USER HEADER IMAGE: ', inc);
              user.attributes.header_image_urls = inc.attributes.urls;
            } else if (inc.type === 'subscription') {
              // console.log('GOT USER SUBS: ', inc);
              user_subs.push(inc);
            } else if (inc.type === 'user_stats') {
              // console.log('GOT USER STATS: ', inc);
              user_stats = inc.attributes;
              if (user.attributes.classification === 'artist') {
                // add up the collections for simplity in the templates. We bundle eps, singles and albums together as 'collections' in the profile UI tabs.
                user_stats.upload_counts.collections = user_stats.upload_counts.singles + user_stats.upload_counts.eps + user_stats.upload_counts.albums;
              }
            } else if (inc.type === 'user') {
              // Going to pre-set classification here as 'artist'
              // user.attributes.classification = 'artist';
              user._manager = inc;
              if (user._manager.id === this.userService.getId()) {
                user._is_manager = true;
              }
            } else if (inc.type === 'user_private') {
              // Only the owner/manager will get this returned.
              user.user_private = inc.attributes;
            }
          });
          user.user_stats = user_stats;
          if (user_subs.length > 0) {
            user._subscriptions = user_subs;
            user = this.setHasCredits(user);
          }

          return user;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  deleteAccount(user: UserV2): Observable<any> {
    return this.http.delete(BASE_URI + '/users/' + user.attributes.permalink).pipe(
      tap(data => {
        console.log('%cACCOUNT DELETED', data);
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  ////// RXJS EXPAND TESTS
  // Used for getting all results from all pages in one observable

  // Tunes
  getArtistTunesV2(permalink: string, tune_only: boolean = false, page: number = 1, per_page: number = 20, skip_loading?: boolean): Observable<{ nextPage: number, tunes: TuneV2[] }> {
    // console.log('getArtistTunesV2: ', permalink, 'page: ', page);
    let params_data = new HttpParams();
    params_data = params_data.set('page[number]', page.toString());
    params_data = params_data.set('page[size]', per_page.toString());

    // if !tune_only
    if (!tune_only) {
      params_data = params_data.set('include[]', 'artist');
      params_data = params_data.append('include[]', 'artist.manager');
      params_data = params_data.append('include[]', 'artist.subscription');
      params_data = params_data.append('include[]', 'tune_stats'); // Only owners or managers will get this data.
      params_data = params_data.append('include[]', 'tune_private');
    }

    if (skip_loading) {
      params_data = params_data.set('skip_loading', 'true');
    }

    return this.http.get<any>(BASE_URI + '/users/' + permalink + '/tunes', { params: params_data })
      .pipe(
        map(data => {
          let next_page;
          if (data.meta.pagination.page < data.meta.pagination.total_pages) {
            next_page = page + 1;
          }
          if (tune_only) {
            return {
              nextPage: next_page,
              tunes: data.data
            }
          }

          data.data.forEach((tune: TuneV2) => {
            const artist_id = tune.relationships.artist.data.id;
            let artist_manager: UserV2;
            let artist_subscriptions: SubscribedPlan[] = [];
            data.included.forEach(inc => {
              if (inc.type === 'user') {
                if (inc.id === artist_id) {
                  tune.artist = inc;
                } else {
                  artist_manager = inc;
                }
              } else if (inc.type === 'subscription') {
                artist_subscriptions.push(inc);
              }
            });

            if (tune.artist.id === this.userService.getId()) {
              tune._is_owner = true;
            }

            if (artist_manager) {
              tune.artist._manager = artist_manager;
              if (artist_manager.id === this.userService.getId()) {
                // To simplify UI template logic.
                tune.artist._is_manager = true;
                tune._is_manager = true;
              }
            }
            if (artist_subscriptions.length > 0) {
              tune.artist._subscriptions = artist_subscriptions;
            }
            tune = this.extractTuneStatsFromIncluded(data.included, tune);
            if (tune.attributes.visibility === 'unlisted') {
              tune._is_unlisted = true;
            }
          });
          return {
            nextPage: next_page,
            tunes: data.data
          }
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }
  // This method will 'auto-paginate' every page of tunes into one array using rxJs 'expand'. Neat. (Or possibly madness)
  getAllArtistTunesV2(permalink: string, tune_only: boolean = false): Observable<TuneV2[]> {
    // console.log('getAllArtistTunesV2: ', permalink);
    return new Observable(observer => {
      this.getArtistTunesV2(permalink, tune_only)
        .pipe(
          expand((data, i) => {
            // if nextPage value exists, get next page or return RxJs' EMPTY, which will finalise the subscription
            return data.nextPage ? this.getArtistTunesV2(permalink, tune_only, data.nextPage) : EMPTY;
          }),
          reduce((acc, data) => {
            return acc.concat(data.tunes);
          }, []),
          catchError((error: HttpErrorResponse) => {
            return this.handleError(error);
          })
        )
        .subscribe((data) => {
          observer.next(data);
          observer.complete();
        });
    });
  }

  // Collections
  getArtistCollectionsV2(permalink: string, page: number = 1, per_page: number = 20, skip_loading?: boolean): Observable<{ nextPage: number, collections: CollectionV2[] }> {
    // console.log('getArtistCollectionsV2: ', permalink, 'page: ', page);
    let params_data = new HttpParams();
    params_data = params_data.set('page[number]', page.toString());
    params_data = params_data.set('page[size]', per_page.toString());
    params_data = params_data.set('include[]', 'tunes');
    params_data = params_data.append('include[]', 'tunes.tune_stats');
    params_data = params_data.append('include[]', 'tunes.tune_private');
    params_data = params_data.append('include[]', 'artist');
    params_data = params_data.append('include[]', 'artist.manager');
    if (skip_loading) {
      params_data = params_data.set('skip_loading', 'true');
    }

    return this.http.get<any>(BASE_URI + '/users/' + permalink + '/collections', { params: params_data })
      .pipe(
        map(data => {
          if (data.data[0] === undefined) {
            // console.log('No (public?) collections: ', data);
            return {
              nextPage: 0,
              collections: []
            }
          }

          let next_page;
          if (data.meta.pagination.page < data.meta.pagination.total_pages) {
            next_page = page + 1;
          }
          // add artist (get id from the first tune artist relationship)
          const artist_id = data.data[0].relationships.artist.data.id;

          let _artist: UserV2;
          let _manager: UserV2;
          // Build the artist and manager data;
          data.included.forEach(inc => {
            if (inc.type === 'user') {
              if (inc.id === artist_id) {
                _artist = inc;
              } else {
                _manager = inc;
              }
            }
          });
          if (_manager) {
            _artist._manager = _manager;
            if (_manager.id === this.userService.getId()) {
              _artist._is_manager = true;
            }
          }
          // add tunes
          data.data.forEach((collection: CollectionV2) => {
            collection._tunes = [];
            collection.artist = _artist;
            if (_artist.id === this.userService.getId()) {
              collection._is_owner = true;
            }
            if (_artist._is_manager) {
              collection._is_manager = true;
            }
            collection.relationships.tunes.data.forEach(tune => {
              data.included.forEach(_include => {
                if (_include.type === 'tune' && _include.id === tune.id) {
                  // Tunes belonging to this collection
                  // Hmmmm... this might not always be the case.
                  _include.artist = _artist;
                  // Can a label create a collection with tunes from multiple artists?
                  if (_include.artist.id === this.userService.getId()) {
                    _include._is_owner = true;
                  }
                  if (_include.artist._is_manager) {
                    // set this on the tune too.
                    _include._is_manager = true;
                  }
                  // extractTuneStats
                  let _tune: TuneV2 = _include;
                  _tune = this.extractTuneStatsFromIncluded(data.included, _tune);
                  collection._tunes.push(_tune);
                  if (_tune.id === collection.id) {
                    collection._is_pseudo_single = true;
                  }
                }
              })
            });
            collection._duration = 0;
            collection._total_tune_plays = 0;
            collection._total_tune_likes = 0;
            collection._total_tune_playlistings = 0;

            collection._tunes.forEach(t => {
              if (t._private) {
                collection._total_tune_plays += t._private.stats.plays;
                collection._total_tune_likes += t._private.stats.likes;
                collection._total_tune_playlistings += t._private.stats.playlistings;
              }
              collection._duration += t.attributes.duration_secs;
            })
          });

          return {
            nextPage: next_page,
            collections: data.data
          }
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  getAllArtistCollections(permalink: string): Observable<CollectionV2[]> {
    return new Observable(observer => {
      this.getArtistCollectionsV2(permalink)
        .pipe(
          expand((data, i) => {
            // if nextPage value exists, get next page or return RxJs' EMPTY, which will finalise the subscription
            return data.nextPage ? this.getArtistCollectionsV2(permalink, data.nextPage) : EMPTY;
          }),
          reduce((acc, data) => {
            return acc.concat(data.collections);
          }, []),
          catchError((error: HttpErrorResponse) => {
            return this.handleError(error);
          })
        )
        .subscribe((data) => {
          observer.next(data);
          observer.complete();
        });
    });
  }

  // Label Artists by page.
  getLabelArtistsV2(permalink: string, page: number = 1, per_page: number = 40, skip_loading?: boolean, compact: boolean = false): Observable<{ nextPage: number, artists: UserV2[], pagination: Pagination }> {
    // console.log('getLabelArtistsV2: page: ', page);
    let params_data = new HttpParams();

    if (skip_loading) {
      params_data = params_data.set('skip_loading', 'true');
    }

    params_data = params_data.set('page[number]', page.toString());
    params_data = params_data.set('page[size]', per_page.toString());
    if (!compact) {
      params_data = params_data.set('include[]', 'user_stats');
      params_data = params_data.append('include[]', 'manager');
      params_data = params_data.append('include[]', 'header_image');
    }

    // console.log('%cLABEL ARTISTS REQUEST: page', 'color:cyan' , page, 'GET /users/' + permalink + '/artists' + params_data.toString());

    return this.http.get<any>(BASE_URI + '/users/' + permalink + '/artists', { params: params_data })
      .pipe(
        map(data => {
          let next_page;
          if (data.meta.pagination.page < data.meta.pagination.total_pages) {
            next_page = page + 1;
          }
          data.data.forEach(artist => {
            artist._subscriptions = [];
            if (data.included) {
              data.included.forEach(inc => {
                if (inc.type === 'user_stats') {
                  if (inc.id === artist.id) {
                    //console.log('GOT ARTIST USER STATS: ', inc.attributes);
                    artist.user_stats = inc.attributes;
                    // add up the collections for simplity in the templates. We bundle eps, singles and albums together as 'collections' in the profile UI tabs.
                    artist.user_stats.upload_counts.collections = artist.user_stats.upload_counts.singles + artist.user_stats.upload_counts.eps + artist.user_stats.upload_counts.albums;
                  }
                } else if (inc.type === 'image' && inc.id && artist.relationships?.image?.data?.id) {
                  if (inc.id === artist.relationships.image.data.id) {
                    //console.log('GOT ARTIST _IMAGE: ', inc.attributes);
                    artist._image = inc.attributes;
                  }
                } else if (inc.type === 'header_image' && inc.id && artist.relationships?.image?.data?.id) {
                  if (inc.id === artist.relationships.image.data.id) {
                    //console.log('GOT ARTIST HEADER _IMAGE: ', inc.attributes);
                    artist._header_image = inc.attributes;
                  }
                } else if (inc.type === 'user') {
                  if (inc.id === artist.relationships.manager.data.id) {
                    //console.log('GOT ARTIST MANAGER: ', inc);
                    artist._manager = inc;
                    if (artist._manager.id === this.userService.getId()) {
                      artist._is_manager = true;
                    }
                  }
                }
              });
            }

            artist = this.setHasCredits(artist);
          });
          return {
            nextPage: next_page,
            artists: data.data,
            pagination: data.meta.pagination
          }
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  setHasCredits(user: UserV2): UserV2 {
    // console.log('%csetHasCredits: ', 'color:yellow', user.attributes.name, user._subscriptions);
    // To simplify template logic, we'll see if any *_remaining values are left.
    if (!user._subscriptions || user._subscriptions.length === 0) {
      return user;
    }
    if (!user._subscriptions[0].attributes.is_active) {
      return user;
    }
    if (
      user._subscriptions[0].attributes.uploads_remaining > 0
    ) {
      user._has_remaining = true;
    }
    return user;
  }

  addTuneToFirstSpin(tune: TuneV2): Observable<TuneV2> {
    // https://amazingtunes.mobi/docs/api/v2/apifirst_spin/create.html
    // POST /first_spin/enter
    const payload = {
      data: {
        id: tune.id,
        type: 'tune'
      }
    };
    return this.http.post<any>(BASE_URI + '/first_spin/enter', payload).pipe(
      map(data => {
        return data.data;
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  getAllLabelArtistsCompact(permalink: string): Observable<CompactArtist[]> {
    let params_data = new HttpParams();
    params_data = params_data.set('skip_loading', 'true');
    params_data = params_data.set('slim', 'true');
    params_data = params_data.set('fields[user][]', 'name');
    params_data = params_data.append('fields[user][]', 'permalink');
    params_data = params_data.append('fields[user][]', 'permalink_in_label');

    return this.http.get<any>(BASE_URI + '/users/' + permalink + '/artists', { params: params_data }).pipe(
      map(data => {
        return data.data.map((_artist: UserV2) => {
          return {
            id: _artist.id,
            name: _artist.attributes.name,
            permalink: _artist.attributes.permalink,
            permalink_in_label: _artist.attributes.permalink_in_label
          }
        });
      }),
      map(data => {
        return data.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
      })
    )
  }

  // Test to try and get a faster list of artists for the main list.
  getAllLabelArtistsFast(permalink: string): Observable<any[]> {
    let params_data = new HttpParams();
    params_data = params_data.set('skip_loading', 'true');
    params_data = params_data.set('slim', 'true');
    params_data = params_data.set('fields[user][]', 'name');
    params_data = params_data.append('fields[user][]', 'image_urls');

    // params_data = params_data.append('fields[user][]','permalink');
    // params_data = params_data.append('fields[user][]','permalink_in_label');
    // params_data = params_data.append('fields[user][]','image_urls.medium');
    // params_data = params_data.append('fields[user][]','meta');

    return this.http.get<any>(BASE_URI + '/users/' + permalink + '/artists', { params: params_data }).pipe(
      tap(data => {
        console.log('Artists fast:', data);
      }),
      // map(data => {
      //   return data.data.map((_artist:UserV2) => {
      //     return {
      //       name:_artist.attributes.name,
      //       permalink:_artist.attributes.permalink,
      //       permalink_in_label:_artist.attributes.permalink_in_label
      //     }
      //   });
      // }),
      // map(data => {
      //   return data.sort(function (a, b) {
      //     return a.name.localeCompare(b.name);
      //   });
      // })
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    )
  }

  // Auto-'expands' to get *ALL* available pages of results into a single Observable array.
  getAllLabelArtistsV2(permalink: string, skip_loading: boolean = false, compact: boolean = false, sorted: boolean = false): Observable<UserV2[]> {
    return new Observable(observer => {
      this.getLabelArtistsV2(permalink, 1, 100, skip_loading, compact)
        .pipe(
          // tap(data => {
          //   console.log('getAllLabelArtists: nextPage:', data.nextPage, data);
          // }),
          expand((data, i) => {
            // if nextPage value exists, get next page or return RxJs' EMPTY, which will finalise the subscription
            return data.nextPage ? this.getLabelArtistsV2(permalink, data.nextPage, 100, skip_loading, compact) : EMPTY;
          }),
          reduce((acc, data) => {
            return acc.concat(data.artists);
          }, []),
          map(data => {
            // Sort Artists alphabetically, then return to subscriber
            if (sorted) {
              console.log('Sort Artists..');
              data.sort(function (a, b) {
                return a.attributes.name.localeCompare(b.attributes.name);
              });
            }

            return data;
          }),
          catchError((error: HttpErrorResponse) => {
            return this.handleError(error);
          })
        )
        .subscribe((data) => {
          observer.next(data);
          observer.complete();
        });
    });
  }

  getTuneV2(id: string, skip_loading?: boolean, bypass_sw?: boolean): Observable<TuneV2> {

    let params_data = new HttpParams();
    params_data = params_data.set('include[]', 'artist');
    params_data = params_data.set('include[]', 'artist.manager');
    params_data = params_data.append('include[]', 'collections');
    params_data = params_data.append('include[]', 'primary_genre');
    params_data = params_data.append('include[]', 'sub_genre');
    params_data = params_data.append('include[]', 'tune_private');
    params_data = params_data.append('include[]', 'tune_stats');
    params_data = params_data.append('include[]', 'image');
    params_data = params_data.append('include[]', 'voice_clips');

    // Don't show loading spinner. (Used when polling a tune for thing like readiness to stream)
    if (skip_loading) {
      params_data = params_data.set('skip_loading', 'true');
    }
    if (bypass_sw) {
      params_data = params_data.set('ngsw-bypass', 'true');
    }

    return this.http.get<any>(BASE_URI + '/tunes/' + id, { params: params_data })
      .pipe(
        map(data => {
          let mapped_tune: TuneV2 = data.data;
          mapped_tune._collections = [];
          mapped_tune._voice_clips = [];
          let mapped_tune_artist_manager: UserV2;
          // Sort through the included and map some _underscored ('private') properties
          data.included.forEach(inc => {
            switch (inc.type) {
              case 'artist':
              case 'user':
                if (inc.id === mapped_tune.relationships.artist.data.id) {
                  mapped_tune.artist = inc;
                } else {
                  mapped_tune_artist_manager = inc;
                }
                break;
              case 'tune_private':
                mapped_tune._private = inc.attributes;
                break;
              case 'image':
                mapped_tune._image = inc.attributes;
                break;
              case 'tune_stats':
                mapped_tune._stats = inc.attributes;
                break;
              case 'collection':
                inc.attributes.id = inc.id;
                mapped_tune._collections.push(inc);
                break;
              case 'voice_clip':
                inc.attributes.id = inc.id;
                mapped_tune._voice_clips.push(inc);
                break;
              case 'genre':
                if (inc.attributes.type === 'primary') {
                  mapped_tune.primary_genre = {
                    id: parseInt(inc.id),
                    name: inc.attributes.name
                  };
                } else if (inc.attributes.type === 'secondary') {
                  mapped_tune.sub_genre = {
                    id: parseInt(inc.id),
                    name: inc.attributes.name
                  };
                }
                break;
              default:
            }
          });

          if (mapped_tune.artist && mapped_tune_artist_manager) {
            mapped_tune.artist._manager = mapped_tune_artist_manager;
            if (mapped_tune.artist._manager.id === this.userService.getId()) {
              // Set flags for simplified  UI template logic.
              mapped_tune.artist._is_manager = true;
              mapped_tune._is_manager = true;
            }
          }
          // delete mapped_tune.relationships;
          if (this.userService.getId() === mapped_tune.artist.id) {
            mapped_tune._is_owner = true;
          }
          if (mapped_tune.attributes.visibility === 'unlisted') {
            mapped_tune._is_unlisted = true;
          }
          return mapped_tune; // TuneV2
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  downloadTune(tune: TuneV2): Observable<TuneV2> {
    let params_data = new HttpParams();
    return this.http.post<any>(BASE_URI + '/tunes/' + tune.id + '/download', params_data).pipe(
      map(data => {
        return data.data;
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  downloadTuneByToken(token: string): Observable<TuneV2> {
    // console.log('Download tune by token ', token);
    const payload = {
      token: token
    };

    return this.http.post<any>(BASE_URI + '/tunes/download', payload).pipe(
      map(data => {
        return data.data;
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  purchaseResource(resource: TuneV2 | CollectionV2): Observable<PaymentV2> {
    // console.log('purchaseResource', resource);
    // Obtain Gateway Payment URL..
    const payload = {
      data: {
        id: resource.id,
        type: resource.type
      }
    }

    return this.http.post<any>(BASE_URI + '/payments/purchases?skip_loading=true', payload).pipe(
      map(data => {
        return data.data;
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }


  verifyPurchase(token: string, payer_id: string, gateway: string): Observable<PaymentVerification> {
    // Verifies Resource purchases and Donations.
    const payload = {
      token: token,
      payer_id: payer_id
    };
    return this.http.post<any>(BASE_URI + '/payments/' + gateway + '/verify', payload).pipe(
      // tap(data => {
      //   console.log('verifyPurchase data: ', data);
      // })
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  getPurchases(page: number = 1, per_page: number = 10): Observable<{ nextPage: number, purchases: any[] }> {

    let params_data = new HttpParams();
    params_data = params_data.set('page[number]', page.toString());
    params_data = params_data.set('page[size]', per_page.toString());

    return this.http.get<any>(BASE_URI + '/payments/purchases', { params: params_data }).pipe(
      // tap(data => {
      //   console.log('getPurchases: ', data);
      // }),
      map(data => {
        let next_page;
        if (data.meta.pagination.page < data.meta.pagination.total_pages) {
          next_page = data.meta.pagination.page + 1;
        }
        return {
          nextPage: next_page,
          purchases: data.data
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    )
  }

  getPurchasedTunes(page: number = 1, per_page: number = 10): Observable<{ nextPage: number, purchases: any[] }> {

    let params_data = new HttpParams();
    params_data = params_data.set('page[number]', page.toString());
    params_data = params_data.set('page[size]', per_page.toString());
    params_data = params_data.set('include[]', 'artist');

    return this.http.get<any>(BASE_URI + '/payments/purchases/tunes', { params: params_data }).pipe(
      tap(data => {
        // console.log('getPurchasedTunes: ', data);
        // insert artist.
        data.data.forEach(tune => {
          data.included.forEach(inc => {
            if (inc.id === tune.relationships.artist.data.id) {
              tune.artist = inc;
            }
          })
        });
      }),
      map(data => {
        let next_page;
        if (data.meta.pagination.page < data.meta.pagination.total_pages) {
          next_page = data.meta.pagination.page + 1;
        }
        return {
          nextPage: next_page,
          purchases: data.data
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    )
  }

  // COMING SOON : Donations ....
  getDonations(page: number = 1, per_page: number = 10): Observable<{ nextPage: number, donations: PaymentV2[] }> {
    // GET /payments/donations
    let params_data = new HttpParams();
    params_data = params_data.set('page[number]', page.toString());
    params_data = params_data.set('page[size]', per_page.toString());
    return this.http.get<any>(BASE_URI + '/payments/donations', { params: params_data }).pipe(
      tap(data => {
        console.log('getDonations data: ', data);
      }),
      map(data => {
        let next_page;
        if (data.meta.pagination.page < data.meta.pagination.total_pages) {
          next_page = data.meta.pagination.page + 1;
        }
        return {
          nextPage: next_page,
          donations: data.data
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  makeDonation(user: UserV2, amount_in_cents: number, source?: any): Observable<PaymentVerification> {
    // POST /payments/donations
    if (!user.meta.accepts_donations) {
      this.snackbar.show('This user cannot accept donations yet.');
      return throwError({ status: -1, message: 'This user cannot accept donations yet.' });
    }

    const payload = {
      data: {
        attributes: {
          amount_in_cents: amount_in_cents,
          currency: user.meta.default_currency
        },
        relationships: {
          recipient: {
            data: {
              id: user.id,
              type: 'user'
            }
          }
        }
      }
    };

    let endpoint: string = BASE_URI + '/payments/donations';
    if (source) {
      // For now, this will be the One Night Stand Episode id (not 'episode number')
      // Format will be { query_key:'ons_id', value: :episode_id }
      endpoint += '?' + source.query_key + '=' + source.value;
    }

    return this.http.post<any>(endpoint, payload).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }


  getCollectionV2(id: string, skip_loading?: boolean): Observable<CollectionV2> {
    // console.log('getCollectionV2: ', id);
    let params_data = new HttpParams();
    params_data = params_data.set('include[]', 'artist');
    params_data = params_data.append('include[]', 'artist.manager');
    params_data = params_data.append('include[]', 'tunes');
    params_data = params_data.append('include[]', 'tunes.tune_stats');
    params_data = params_data.append('include[]', 'tunes.tune_private'); // Only owners and managers will get this data
    params_data = params_data.append('include[]', 'image');
    if (skip_loading) {
      params_data = params_data.set('skip_loading', 'true');
    }
    let collection: CollectionV2;
    return this.http.get<any>(BASE_URI + '/collections/' + id, { params: params_data })
      .pipe(
        map(data => {
          // console.log('V2 Collection: ', data);
          collection = data.data;
          collection._tunes = [];
          const artist_id = data.data.relationships.artist.data.id;
          let _manager: UserV2;
          data.included.forEach(inc => {
            switch (inc.type) {
              case 'user':
                if (inc.id === artist_id) {
                  collection.artist = inc;
                } else {
                  _manager = inc;
                }
                break;
              case 'image':
                collection._image = inc.attributes;
                break;
              case 'tune':
                collection._tunes.push(inc);
                if (inc.id === collection.id) {
                  collection._is_pseudo_single = true;
                }
                break;
              default:
            }
          });
          if (_manager) {
            collection.artist._manager = _manager;
            if (_manager.id === this.userService.getId()) {
              collection._is_manager = true;
              collection.artist._is_manager = true;
            }
          }
          collection._duration = 0;
          collection._total_tune_plays = 0;
          collection._total_tune_likes = 0;
          collection._total_tune_playlistings = 0;

          collection._tunes.forEach((tune, i) => {
            tune._unique_id = i + '_' + collection.id;
            tune.artist = collection.artist;
            if (this.userService.getId() === tune.artist.id) {
              tune._is_owner = true;
            }
            if (tune.artist._is_manager) {
              tune._is_manager = true;
            }
            tune = this.extractTuneStatsFromIncluded(data.included, tune);
            if (tune._private) {
              collection._total_tune_plays += tune._private.stats.plays;
              collection._total_tune_likes += tune._private.stats.likes;
              collection._total_tune_playlistings += tune._private.stats.playlistings;
            }
            collection._duration += tune.attributes.duration_secs;
          });
          if (this.userService.getId() === collection.artist.id) {
            collection._is_owner = true;
          }

          return collection;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  // This method will 'auto-paginate' every page of playlists into one array using rxJs 'expand'.
  // Used for listing available playlists in a dialog when adding tunes to one.
  getAllUserPlaylistsV2(permalink: string, include_tunes: boolean = false): Observable<PlaylistV2[]> {
    // console.log('getAllUserPlaylistsV2: ', permalink);
    const per_batched_page: number = 20;
    return new Observable(observer => {
      this.getUserPlaylistsV2(permalink)
        .pipe(
          expand((data, i) => {
            // if nextPage value exists, get next page or return RxJs' EMPTY, which will finalise the subscription
            return data.nextPage ? this.getUserPlaylistsV2(permalink, data.nextPage, per_batched_page, include_tunes) : EMPTY;
          }),
          reduce((acc, data) => {
            return acc.concat(data.playlists);
          }, []),
          catchError((error: HttpErrorResponse) => {
            return this.handleError(error);
          })
        )
        .subscribe((data) => {
          observer.next(data);
          observer.complete();
        });
    });
  }

  // Playlists
  getUserPlaylistsV2(permalink: string, page: number = 1, per_page: number = 20, include_tunes: boolean = false): Observable<{ nextPage: number, playlists: PlaylistV2[] }> {
    let params_data = new HttpParams();
    params_data = params_data.set('page[number]', page.toString());
    params_data = params_data.set('page[size]', per_page.toString());
    params_data = params_data.set('include[]', 'user');

    if (include_tunes) {
      // console.log('including tunes');
      params_data = params_data.append('include[]', 'tunes');
    }
    // console.log('%cPL', 'color:yellow', permalink, page, per_page, include_tunes);
    return this.http.get<any>(BASE_URI + '/users/' + permalink + '/playlists', { params: params_data })
      .pipe(
        map(data => {
          data.data.forEach(playlist => {
            // console.log(playlist.attributes.name, data.meta.pagination.page , data.meta.pagination.total_pages);
            playlist._tunes = [];
            data.included.forEach(inc => {
              if (inc.type === 'user') {
                playlist._curator = inc; // we only requested this one include, so no need to loop.
                if (playlist._curator.id === this.userService.getId()) {
                  playlist._is_owner = true;
                  // can we get the manager of user?
                }
              } else if (inc.type === 'tune') {
                playlist._tunes.push(inc);
              }
            });
          });
          let next_page;
          if (data.meta.pagination.page < data.meta.pagination.total_pages) {
            next_page = data.meta.pagination.page + 1;
          }
          return {
            nextPage: next_page,
            playlists: data.data
          }
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  // Get Show airing playlist
  getShowPlaylistTunes(skip_loading: boolean, show_slug: string, airing_id: number, timestamp_start?: number): Observable<TuneV2[]> {
    let params_data = new HttpParams();
    params_data = params_data.set('show', show_slug);
    params_data = params_data.set('airing_id', airing_id.toString());
    params_data = params_data.set('include[]', 'tune');
    params_data = params_data.append('include[]', 'tune.artist');

    if (skip_loading) {
      params_data = params_data.set('skip_loading', 'true');
    }

    return this.http.get<any>(BASE_URI + '/radio/show_playlist', { params: params_data }).pipe(
      map(data => {
        let tunes: TuneV2[] = [];
        data.data.forEach((item: RadioLogEntry) => {
          //console.log('tune: id:', item.relationships.tune);
          if (item.relationships.tune.data) {
            const _tune: TuneV2 = Object.assign({}, this.extractTuneAndArtistFromIncludedByTuneId(item.relationships.tune.data.id, data.included));
            _tune._radio_log_entry = item; // Adding this to see if it's possible to sync with the long mp3 (rewind) playback timing.
            tunes.push(_tune);
          }
        });

        if (timestamp_start) {
          tunes.forEach((tune, i) => {
            // console.log(i, new Date(tune._radio_log_entry.attributes.aired_at), (new Date(tune._radio_log_entry.attributes.aired_at).getTime()  / 1000) - this.timestamp_start );
            tune._sync_time = parseFloat(((new Date(tune._radio_log_entry.attributes.aired_at).getTime() / 1000) - timestamp_start).toFixed(2));
            if (i === 0) {
              // console.log('shifting index 0 from start time: ', tune._sync_time);
              // put the first tune at zero.
              tune._sync_time = 0;
            }
            // console.log(i, tune._sync_time);
          });

        }
        // console.log('Show Playlist tunes: ', tunes);
        return tunes;
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    )
  }

  // Get individual Playlist
  getPlaylistV2(id: string, skip_loading?: boolean, hide_private_tunes: boolean = false): Observable<PlaylistV2> {
    let params_data = new HttpParams();
    if (skip_loading) {
      params_data = params_data.set('skip_loading', 'true');
    }
    params_data = params_data.set('include[]', 'user');
    params_data = params_data.append('include[]', 'image');
    params_data = params_data.append('include[]', 'entries');
    params_data = params_data.append('include[]', 'tunes');
    params_data = params_data.append('include[]', 'tunes.artist');

    return this.http.get<any>(BASE_URI + '/playlists/' + id, { params: params_data })
      .pipe(
        // tap(data => {
        //   console.log('getPlaylistV2', data);
        // }),
        map(data => {
          let _curator: UserV2;
          data.included.forEach(inc => {
            if (inc.type === 'user' && inc.id === data.data.relationships.user.data.id) {
              _curator = inc;
            }
            if (inc.type === 'image') {
              data.data._image = inc.attributes;
            }
          });
          if (_curator.id === this.userService.getId()) {
            data.data._is_owner = true;
          }
          data.data._curator = _curator;
          // tunes and entries relationships map one-to-one, so...
          data.data.relationships.tunes.data.forEach((_t, i) => {
            _t.entry_id = data.data.relationships.entries.data[i].id;
            // console.log('_t.entry_id', _t.entry_id);
          });

          //console.log('data.data.relationships.tunes.data', data.data.relationships.tunes.data);
          //console.log('data.data.relationships.entries.data', data.data.relationships.entries.data);

          let tunes: TuneV2[] = this.extractTunesAndArtistsAndEntryIdFromIncluded(data.included, data.data.relationships.tunes.data, 'playlist');

          // console.log('%cPLAYLIST TUNES', 'color:orange', tunes);
          // console.log('TUNES ', _tunes);
          // // need to iterate like this due to potential duplicates.
          // let type = 'FOO_';
          // for (let _i = 0; _i < tunes.length; _i++) {
          //   tunes[_i]._unique_id = type + '_' + tunes[_i].meta.entry_id.toString() + '_' + _i + '_' + tunes[_i].id;
          //   console.log('%cUNEEK:', 'color:yellow', tunes[_i]._unique_id );
          // }

          data.data._tunes = tunes;
          return data.data;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  deletePlaylistTunes(playlist_id: string, tunes: TuneV2[]): Observable<PlaylistV2> {
    // DELETE /playlists/:uuid/relationships/entries (now using /entries not /tunes)
    let payload = {
      data: []
    };
    // Can handle multiple tune entries, but the UI will only do one at time at the moment, as and when they are deleted from the list in the edit form.
    tunes.forEach(tune => {
      payload.data.push({
        id: tune.meta.entry_id,
        type: 'playlist_entry'
      });
    });
    // The DELETE request body needs to be built like this...
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: payload,
    };
    //
    return this.http.delete(BASE_URI + '/playlists/' + playlist_id + '/relationships/entries', options).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    )
  }

  reorderPlaylistTunes(playlist_id: string, tunes: TuneV2[]): Observable<PlaylistV2> {
    // PATCH /playlists/:uuid/relationships/tunes
    let payload = {
      data: []
    };
    tunes.forEach(tune => {
      payload.data.push({
        id: tune.id,
        type: 'tune'
      });
    });
    // console.log('reorderPlaylistTunes payload: ', payload);
    return this.http.patch(BASE_URI + '/playlists/' + playlist_id + '/relationships/tunes', { data: payload.data }).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    )
  }

  addPlaylistTunes(playlist_id: string, tunes: TuneV2[]): Observable<PlaylistV2> {
    // POST /playlists/:uuid/relationships/tunes
    let payload = {
      data: []
    };
    tunes.forEach(tune => {
      payload.data.push({
        id: tune.id,
        type: 'tune'
      });
    });
    return this.http.post<any>(BASE_URI + '/playlists/' + playlist_id + '/relationships/tunes', payload).pipe(
      map(data => {
        return data.data;
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    )
  }

  createNewPlaylist(name: string): Observable<PlaylistV2> {
    // Used when add a tune to a new playlist.
    // Only name needed at this point.
    const payload = {
      data: {
        type: 'playlist',
        attributes: {
          name: name
        }
      }
    };
    return this.http.post<any>(BASE_URI + '/playlists', payload).pipe(
      map(data => {
        return data.data;
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  // Likes

  likeResource(resource: TuneV2 | CollectionV2 | UserV2 | PlaylistV2 | ExternalVideo): Observable<TuneV2 | CollectionV2 | UserV2 | PlaylistV2 | ExternalVideo> {
    const payload = {
      data: {
        id: resource.id,
        type: resource.type
      }
    };
    // console.log('%cLike Resource Payload', 'color-red', payload);
    if (resource.meta.liked_by_user) {
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: payload,
      };
      return this.http.delete(BASE_URI + '/likes', options).pipe(
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
    } else {
      return this.http.post(BASE_URI + '/likes', payload).pipe(
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
    }
  }

  getLikesV2(permalink: string, page: number = 1, per_page: number = 12, type?: string, skip_loading?: boolean): Observable<{ nextPage: number, likes: any[] }> {
    let params_data = new HttpParams();
    params_data = params_data.set('page[number]', page.toString());
    params_data = params_data.set('page[size]', per_page.toString());
    params_data = params_data.set('include[]', 'target');
    let path = type ? 'likes/' + type : 'likes';
    if (!type || type === 'tunes' || type === 'collections') {
      params_data = params_data.append('include[]', 'target.artist');
      //params_data = params_data.append('include[]', 'target.tune.artist');
    }
    if (!type || type === 'external_videos' || type === 'playlists' || type === 'videos') {
      params_data = params_data.append('include[]', 'target.user');
    }

    if (!type || type === 'external_videos') {
      // params_data = params_data.append('include[]', 'target.external_video.user');
      params_data = params_data.append('include[]', 'target.external_video.external_video_stats');

    }
    if (!type || type === 'videos') {
      // params_data = params_data.append('include[]', 'target.video.user');
      params_data = params_data.append('include[]', 'target.video.video_stats');
    }
    if (!type || type === 'collections') {
      // params_data = params_data.append('include[]', 'target.collection.artist');
      params_data = params_data.append('include[]', 'target.tunes');
    }
    if (!type || type === 'playlists') {
      // No need to get tunes here. The playlist-play-button will now load when played first.
      // params_data = params_data.append('include[]', 'target.tunes');
    }

    if (skip_loading) {
      params_data = params_data.set('skip_loading', 'true');
    }

    return this.http.get<any>(BASE_URI + '/users/' + permalink + '/' + path, { params: params_data })
      .pipe(
        tap(data => {
          data.data.forEach(like => {
            const target_id = like.relationships.target.data.id;
            // console.log('target: ', target_id, like.relationships.target.data.type);
            like._target = this.extractLikeTargetFromIncluded(target_id, data.included);
          });
        }),
        map(data => {
          let next_page;
          if (data.meta.pagination.page < data.meta.pagination.total_pages) {
            next_page = page + 1;
          }
          return {
            nextPage: next_page,
            likes: data.data
          };
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }


  extractLikeTargetFromIncluded(target_id: string, included: any[]) {
    let target: any;
    included.forEach(inc => {
      if (inc.id === target_id) {
        if (inc.type === 'tune') {
          target = inc;
          target.artist = this.extractUserFromTarget(target.relationships.artist.data.id, included);
        } else if (inc.type === 'external_video') {
          target = inc;
          target.artist = this.extractUserFromTarget(target.relationships.user.data.id, included);
          // extract stats from external_video
          included.forEach(inc => {
            if (inc.id === target.id && inc.type === 'external_video_stats') {
              target._stats = inc.attributes;
            }
          })
        } else if (inc.type === 'video') {
          target = inc;
          target.artist = this.extractUserFromTarget(target.relationships.user.data.id, included);
          // extract stats from video
          included.forEach(inc => {
            if (inc.id === target.id && inc.type === 'video_stats') {
              target._stats = inc.attributes;
            }
          })
        } else if (inc.type === 'playlist') {
          target = inc;
          target._curator = this.extractUserFromTarget(target.relationships.user.data.id, included);
          // console.log('playlist TARGET: ', target);
          // console.log('playlist Curator: ', target._curator);
          // console.log('playlist tunes rels: ', target.relationships.tunes);
          target._tunes = [];
          // Tunes will now be lazy loaded.
        } else if (inc.type === 'collection') {
          //console.log('collection TARGET: ', target );
          target = inc;
          target.artist = this.extractUserFromTarget(target.relationships.artist.data.id, included);
          //console.log('collection Artist: ', target.artist);
          //console.log('collection tunes rels: ', target.relationships.tunes);
          target._tunes = [];
          target.relationships.tunes.data.forEach(rel => {
            included.forEach(_inc => {
              if (_inc.id === rel.id) {
                //console.log('Collection Tune: ', _inc);
                _inc.artist = target.artist; // Assuming the tunes artist is always the same as the Collection artist
                target._tunes.push(_inc);
              }
            });
          });
        } else if (inc.type === 'user') {
          target = inc;
        }
      }
    });
    // console.log('%cFINAL _TARGET', 'color:yellow', target);
    return target;
  }

  extractUserFromTarget(user_id: string, included: any[]) {
    let user: UserV2;
    included.forEach(inc => {
      if (inc.type === 'user') {
        if (inc.id === user_id) {
          // console.log('extract user: ', inc.id);
          user = inc;
        }
      }
    });
    return user;
  }

  // List of all airplays of tunes owned by the logged-in artist (or label)
  // Pagination: GET /users/rockin-records/airplays?page[number]=2&page[size]=2
  // Exact time pagination: GET /users/rockin-records/airplays?filter[time][before]=1606149519&filter[time][after]=1606149459

  getAirplays(permalink: string, page: number = 1, per_page: number = 20): Observable<AirplaysV2> {
    let params_data = new HttpParams();
    params_data = params_data.set('page[number]', page.toString());
    params_data = params_data.set('page[size]', per_page.toString());
    params_data = params_data.set('include[]', 'tune');
    params_data = params_data.append('include[]', 'tune.artist');
    params_data = params_data.append('include[]', 'show_broadcast');

    params_data = params_data.append('include[]', 'station');

    return this.http.get<any>(BASE_URI + '/users/' + permalink + '/airplays', { params: params_data }).pipe(
      map(data => {
        data.data.forEach((_airplay: RadioLogEntry) => {
          let __tune: TuneV2 = this.extractTuneAndArtistFromIncludedByTuneId(_airplay.relationships.tune.data.id, data.included);
          if (_airplay.relationships.show_broadcast.data) {
            // console.log('show broadcast id: ', _airplay.relationships.show_broadcast.data.id);
            data.included.forEach(inc => {
              if (inc.id === _airplay.relationships.show_broadcast.data.id) {
                _airplay.relationships.show_broadcast.data = inc;
              }
            });
          }

          if (_airplay.relationships.station.data) {
            data.included.forEach(inc => {
              if (inc.id === _airplay.relationships.station.data.id) {
                _airplay.relationships.station.data = inc;
              }
            });
          }

          _airplay._tune = __tune;

        });
        delete data.included;
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  // List all airplays a tune has had.
  getTuneAirplays(tune_id: string, page: number = 1, per_page: number = 20): Observable<AirplaysV2> {
    ;
    let params_data = new HttpParams();
    params_data = params_data.set('page[number]', page.toString());
    params_data = params_data.set('page[size]', per_page.toString());
    return this.http.get<any>(BASE_URI + '/tunes/' + tune_id + '/airplays', { params: params_data }).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  // External Videos
  addExternalVideo(url: string, user?: UserV2): Observable<any> {
    let payload: any = {
      data: {
        type: 'external_video',
        attributes: {
          url: url
        }
      }
    };
    // Label Artists
    if (user) {
      payload.data.relationships = {
        user: {
          data: {
            type: 'user',
            id: user.id
          }
        }
      }
    }
    // console.log('new video payload: ', payload);
    return this.http.post<any>(BASE_URI + '/external_videos', payload).pipe(
      tap(data => {
        // updates stored user data
        this.verifyUserV2().subscribe();
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  deleteExternalVideo(id: string): Observable<any> {
    return this.http.delete<any>(BASE_URI + '/external_videos/' + id).pipe(
      tap(data => {
        // updates stored user data
        this.verifyUserV2().subscribe();
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  getExternalVideo(id: string, skip_loading?: boolean): Observable<ExternalVideo> {
    let params_data = new HttpParams();
    // params_data = params_data.set('include[]', 'user');
    if (skip_loading) {
      params_data = params_data.set('skip_loading', 'true');
    }
    params_data = params_data.set('include[]', 'user');
    params_data = params_data.append('include[]', 'user.manager');
    params_data = params_data.append('include[]', 'external_video_stats');
    return this.http.get<any>(BASE_URI + '/external_videos/' + id, { params: params_data }).pipe(
      map(data => {
        let video: ExternalVideo = data.data;
        video = this.extractUserFromIncluded(data.included, video);
        return video;
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  getLatestExternalVideos(page: number = 1, per_page: number = 20, skip_loading: boolean = true): Observable<{ nextPage: number, videos: ExternalVideo[] }> {
    let params_data = new HttpParams();
    if (skip_loading) {
      params_data = params_data.set('skip_loading', 'true');
    }
    params_data = params_data.set('page[number]', page.toString());
    params_data = params_data.set('page[size]', per_page.toString());
    params_data = params_data.set('include[]', 'user');
    params_data = params_data.append('include[]', 'external_video_stats');

    return this.http.get<any>(BASE_URI + '/external_videos', { params: params_data }).pipe(
      tap(data => {
        data.data.forEach(video => {
          video = this.extractUserFromIncluded(data.included, video);
          delete video.relationships;
        });
        delete data.included;
      }),
      map(data => {
        let next_page;
        if (data.meta.pagination.page < data.meta.pagination.total_pages) {
          next_page = page + 1;
        }
        return {
          nextPage: next_page,
          videos: data.data
        };
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    )
  }

  getArtistExternalVideos(page: number = 1, per_page: number = 20, permalink: string, skip_loading?: boolean): Observable<{ nextPage: number, videos: ExternalVideo[] }> {
    let params_data = new HttpParams();
    if (skip_loading) {
      params_data = params_data.set('skip_loading', 'true');
    }
    // pagination
    params_data = params_data.set('page[number]', page.toString());
    params_data = params_data.set('page[size]', per_page.toString());
    params_data = params_data.set('include[]', 'user');
    params_data = params_data.append('include[]', 'user.manager');
    params_data = params_data.append('include[]', 'external_video_stats');
    return this.http.get<any>(BASE_URI + '/users/' + permalink + '/external_videos', { params: params_data }).pipe(
      map(data => {
        data.data.forEach(video => {
          video = this.extractUserFromIncluded(data.included, video);
        })
        let next_page;
        if (data.meta.pagination.page < data.meta.pagination.total_pages) {
          next_page = page + 1;
        }
        return {
          nextPage: next_page,
          videos: data.data
        };
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  // Internal videos
  getVideo(id: string, skip_loading?: boolean): Observable<Video> {
    let params_data = new HttpParams();
    if (skip_loading) {
      params_data = params_data.set('skip_loading', 'true');
    }
    params_data = params_data.set('include[]', 'user');
    params_data = params_data.append('include[]', 'user.manager');
    params_data = params_data.append('include[]', 'video_stats');
    params_data = params_data.append('include[]', 'primary_genre');
    params_data = params_data.append('include[]', 'sub_genre');
    // associated tune
    params_data = params_data.append('include[]', 'tune');

    return this.http.get<any>(BASE_URI + '/videos/' + id, { params: params_data }).pipe(
      map(data => {
        let video: Video = data.data;
        video = this.extractUserFromIncluded(data.included, video);
        data.included.forEach(inc => {
          if (inc.type === 'video_stats') {
            video._stats = inc.attributes;
          } else if (inc.type === 'genre') {
            if (inc.attributes.type === 'primary') {
              video.primary_genre = {
                id: parseInt(inc.id),
                name: inc.attributes.name
              };
            } else if (inc.attributes.type === 'secondary') {
              video.sub_genre = {
                id: parseInt(inc.id),
                name: inc.attributes.name
              };
            }
          } else if (inc.type === 'tune') {
            // console.log('video tune: ', inc);
            video._tune = inc;
          }
        });
        return video;
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  getArtistVideos(page: number = 1, per_page: number = 20, permalink: string, skip_loading?: boolean): Observable<{ nextPage: number, videos: Video[] }> {
    let params_data = new HttpParams();
    if (skip_loading) {
      params_data = params_data.set('skip_loading', 'true');
    }
    // pagination
    params_data = params_data.set('page[number]', page.toString());
    params_data = params_data.set('page[size]', per_page.toString());
    params_data = params_data.set('include[]', 'user');
    params_data = params_data.append('include[]', 'user.manager');
    params_data = params_data.append('include[]', 'video_stats');

    return this.http.get<any>(BASE_URI + '/users/' + permalink + '/videos', { params: params_data }).pipe(
      map(data => {
        data.data.forEach(video => {
          video = this.extractUserFromIncluded(data.included, video);
        })
        let next_page;
        if (data.meta.pagination.page < data.meta.pagination.total_pages) {
          next_page = page + 1;
        }
        return {
          nextPage: next_page,
          videos: data.data
        };
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  getLatestVideos(page: number = 1, per_page: number = 20, skip_loading: boolean = true): Observable<{ nextPage: number, videos: Video[] }> {
    let params_data = new HttpParams();
    if (skip_loading) {
      params_data = params_data.set('skip_loading', 'true');
    }
    params_data = params_data.set('page[number]', page.toString());
    params_data = params_data.set('page[size]', per_page.toString());
    params_data = params_data.set('include[]', 'user');
    params_data = params_data.append('include[]', 'video_stats');

    return this.http.get<any>(BASE_URI + '/first_look/videos', { params: params_data }).pipe(
      tap(data => {
        data.data.forEach(video => {
          video = this.extractUserFromIncluded(data.included, video);
          delete video.relationships;
        });
        delete data.included;
      }),
      map(data => {
        let next_page;
        if (data.meta.pagination.page < data.meta.pagination.total_pages) {
          next_page = page + 1;
        }
        return {
          nextPage: next_page,
          videos: data.data
        };
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    )
  }

  createVideo(video: Video): Observable<any> {
    // console.log('create video', video);
    return this.http.post<any>(BASE_URI + '/videos', { data: video }).pipe(
      tap(data => {
        // updates stored user data
        this.verifyUserV2().subscribe();
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  updateVideo(video: Video): Observable<any> {
    // console.log('edit video ', video);
    return this.http.patch<any>(BASE_URI + '/videos/' + video.id, { data: video }).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  deleteVideo(video: Video): Observable<any> {
    // console.log('delete video ', video);
    return this.http.delete<any>(BASE_URI + '/videos/' + video.id).pipe(
      tap(data => {
        // updates stored user data
        this.verifyUserV2().subscribe();
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  getVideoThumbnails(video: Video): Observable<VideoThumbnails> {
    // GET /video/:uuid/thumbnails
    return this.http.get<VideoThumbnails>(BASE_URI + '/videos/' + video.id + '/thumbnails').pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  updateVideoThumbnail(video: Video, thumbnail_index: number, file?: File): Observable<Video> {
    // POST /video/:uuid/thumbnail
    // params: for thumbnail index use `index`.
    // for a new File use `file`

    // Update thumbnail index
    if (!file && thumbnail_index > -1) {
      return this.http.post<any>(BASE_URI + '/videos/' + video.id + '/thumbnail', { index: thumbnail_index }).pipe(
        map(data => {
          return data.data;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
    }
    // Upoad new thumbnail
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<any>(BASE_URI + '/videos/' + video.id + '/thumbnail', formData, { reportProgress: false }).pipe(
      map(data => {
        return data.data;
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  getVideoStreamingUrl(video_id: string): Observable<VideoStream> {
    let params_data = new HttpParams();
    params_data = params_data.set('skip_loading', 'true');
    return this.http.get<any>(BASE_URI + '/stream/video/' + video_id, { params: params_data }).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  extractTuneStatsFromIncluded(included: any[], tune: TuneV2): TuneV2 {
    included.forEach(inc => {
      if (inc.id === tune.id) {
        if (inc.type === 'tune_stats') {
          tune._stats = inc.attributes;
        } else if (inc.type === 'tune_private') {
          tune._private = inc.attributes;
        }
      }
    });
    return tune;
  }

  extractUserFromIncluded(included: any[], video: any): any { //ExternalVideo

    let _manager: UserV2;
    included.forEach(inc => {
      if (inc.type === 'user') {
        if (inc.id === video.relationships.user.data.id) {
          video.artist = inc;
          if (video.artist.id === this.userService.getId()) {
            video._is_owner = true;
          }
        } else {
          _manager = inc;
        }
      } else if (inc.type === 'external_video_stats') {
        if (inc.id === video.relationships.external_video_stats.data.id) {
          // console.log('EXTERNAL VIDEO STATS: ', inc);
          video._stats = inc.attributes;
        }
      } else if (inc.type === 'video_stats') {
        if (inc.id === video.relationships.video_stats.data.id) {
          // console.log('INTERNAL VIDEO STATS: ', inc);
          video._stats = inc.attributes;
        }
      }
    });
    if (_manager) {
      video.artist._manager = _manager;
      if (video.artist._manager.id === this.userService.getId()) {
        video.artist._is_manager = true;
        video._is_manager = true;
      }
    }

    return video;
  }

  // Returns a list of TuneV2[] (with their associated UserV2 artist), extracted from a list of `included` data which
  // included contain a list of 'tune' and 'user' types from the request: include[]=tunes&incllude[]=tunes.artist in getPlaylistV2 and getCollectionV2
  extractTunesAndArtistsAndEntryIdFromIncluded(included: any[], relationshipsTunes: any[], type?: string): TuneV2[] {
    // console.log(relationshipsTunes, included);
    // console.log('---------------------------');
    let _tunes: TuneV2[] = [];
    relationshipsTunes.forEach(rel => {
      const _tune_id = rel.id;
      const _entry_id = rel.entry_id;
      included.forEach(inc => {
        //console.log(inc, rel);
        if (inc.type === 'tune' && inc.id === rel.id) {
          let _tune: TuneV2 = {
            ...inc,
            meta: {
              entry_id: _entry_id
            },
            _unique_id: _entry_id + '_' + _tune_id
          }; // use spread syntax to copy uniquely and insert entry id

          //_tune.meta.entry_id = _entry_id; // Insert meta.entry_id

          included.forEach(_inc => {
            if (_inc.id === _tune.relationships.artist.data.id) {
              _tune.artist = _inc;
            }
          });
          // console.log('playlist entry_id: ', _entry_id, _tune_id);
          // _tune._unique_id = _entry_id + '_' + _tune_id;
          _tunes.push(_tune);
        }
      });


    });
    return _tunes;
  }

  getAnnualCharts(): Observable<AnnualCharts> {
    return this.http.get<AnnualCharts>(BASE_URI + '/charts/annual')
  }

  getChartV2(chart_path: any = 'top40', count: number = 40, skip_loading: boolean = false): Observable<ChartV2Response> {
    // console.log('%cgetChartV2:chart_path','color:yellow' ,chart_path); // chart_path eg: `annual/2020`
    let params_data = new HttpParams();
    // For front page top 5.
    params_data = params_data.set('page[size]', count.toString());
    params_data = params_data.set('include[]', 'tune');
    params_data = params_data.append('include[]', 'tune.artist');

    if (skip_loading) {
      params_data = params_data.set('skip_loading', 'true');
    }
    return this.http.get<ChartV2Response>(BASE_URI + '/charts/' + chart_path + '/entries', { params: params_data }).pipe(
      // tap(data => {
      //   console.log('raw chart data: ', data);
      // }),
      map(data => {
        let tunes: TuneV2[] = [];
        data.data.forEach((tune_data, i) => {
          // console.log('raw tune data : ', tune_data);
          if (tune_data.relationships.tune && tune_data.relationships.tune.data) {
            let _tune = this.extractTuneAndArtistFromIncludedByTuneId(tune_data.relationships.tune.data.id, data.included);
            // console.log('TUNE', _tune)
            if (_tune.artist) {
              _tune.chart = tune_data.attributes; // Add chart movement data
              // console.log('full tune data : ', _tune);
              _tune._unique_id = chart_path + '_' + (i + 1).toString();
              delete _tune.relationships; // no need for this now.
              tunes.push(_tune);
            } else {
              console.log('%cCHART TUNE HAS NO ARTIST', 'color:hotpink', i+1, _tune)
            }

          }
        })
        data._tunes = tunes;
        // return full ChartResponse to retain .meta data options depending on chart type
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    )
  }

  // Admin-curated playlists via Staff Portal
  // Defaults to 3 for the home page.
  getFeaturedPlaylists(page: number = 1, per_page: number = 5, skip_loading: boolean = true): Observable<{ nextPage: number, playlists: PlaylistV2[] }> {

    let params_data = new HttpParams();
    if (skip_loading) {
      params_data = params_data.set('skip_loading', 'true');
    }
    params_data = params_data.set('page[number]', page.toString());
    params_data = params_data.set('page[size]', per_page.toString());
    // Need user to be able to get playlist page url (includes curator permalink)
    params_data = params_data.set('include[]', 'user');

    return this.http.get<any>(BASE_URI + '/featured/playlists', { params: params_data }).pipe(
      tap(data => {
        data.data.forEach(_data => {
          let _curator: UserV2 = this.extractUserFromTarget(_data.relationships.user.data.id, data.included);
          _data._curator = _curator;
        });
      }),
      map(data => {
        let next_page;
        if (data.meta.pagination.page < data.meta.pagination.total_pages) {
          next_page = page + 1;
        }
        return {
          nextPage: next_page,
          playlists: data.data
        };
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    )
  }

  //////////////////////////////////////////////// $$
  // Subscriptions
  getSubscriptionPlans(all: boolean = false): Observable<SubscriptionPlans> {
    // Lists available plans for the site being accessed
    return this.http.get<any>(BASE_URI + '/subscriptions/plans' + (all ? '/all' : '')).pipe(
      map(data => {
        // console.log('plans: ', data);
        // order by data.data.attributes.payment_amount ascending.
        data.data.sort((a, b) => (a.attributes.payment_amount > b.attributes.payment_amount) ? 1 : -1)
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    )
  }

  doSubscription(plan_id: string, gateway: string, card_token?: string, card_scheme?: string): Observable<SubscriptionPayment> {
    // Fetch a URL to redirect the user to the payment gateway for payment

    let payload: any = { plan_id: plan_id };

    // To be deprecated
    if (gateway === 'checkout' && card_token && card_scheme) {
      payload.card_token = card_token;
      payload.card_scheme = card_scheme;
    }
    payload.payment_json = 'true'; // Required to return 'subscription_payment' for now.
    return this.http.post<any>(BASE_URI + '/subscriptions/' + gateway, payload).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    )
  }

  verifySubscription(token: string, gateway: string, subscription_id?: string): Observable<SubscriptionPayment> {
    // Following payment, activate the subscription using the token given by the gateway
    let payload: any = { token: token };
    payload.payment_json = 'true';

    if (subscription_id) {
      payload.subscription_id = subscription_id;
    }

    return this.http.post<any>(BASE_URI + '/subscriptions/' + gateway + '/verify', payload).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    )
  }

  getSubscriptionPayments(): Observable<SubscriptionPayments> {
    let params_data = new HttpParams();
    params_data = params_data.set('skip_loading', 'true');

    return this.http.get<any>(BASE_URI + '/subscriptions/payments', { params: params_data }).pipe(
      tap(data => {
        console.log('subscription payments: ', data);
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    )
  }

  getSubscription(): Observable<MemberSubscription> {
    let params_data = new HttpParams();
    params_data = params_data.set('skip_loading', 'true');
    params_data = params_data.set('include[]', 'funding_source');
    params_data = params_data.append('include[]', 'last_payment');
    params_data = params_data.append('include[]', 'payments');
    params_data = params_data.append('include[]', 'recurring_plan');

    return this.http.get<MemberSubscription>(BASE_URI + '/subscriptions', { params: params_data }).pipe(
      // tap(data => {
      //   console.log('tapped Subscription data', data);
      // }),
      map(data => {
        let recurring_plan: Plan;
        let last_payment: Payment;
        let all_payments: Payment[] = [];
        data.included.forEach(_data => {
          // FundingSource
          if (data.data.relationships.funding_source.data?.id === _data.id && _data.type === 'funding_source') {
            data.data.relationships.funding_source.data = _data;
          }
          // All payments
          if (_data.type === 'subscription_payment') {
            all_payments.push(_data);
            // Last Payment
            if (data.data.relationships.last_payment.data.id === _data.id) {
              last_payment = _data;
            }
          }
          // Recurring Plan
          if (_data.type === 'subscription_plan') {
            recurring_plan = _data;
          }
        });

        data.data.relationships.last_payment.data = last_payment;
        data.data.relationships.payments.data = all_payments;
        data.data.relationships.recurring_plan.data = recurring_plan;

        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    )
  }

  doStripeFundingSource(member_subscription_id: string): Observable<SubscriptionPayment> {
    // Used for Stripe as funding Source setup. Returns data containing `data.meta.stripe_client_secret`

    let payload: any = { subscription_id: member_subscription_id };
    // payload.payment_json = 'true'; // Required to return 'subscription_payment' for now.

    return this.http.post<any>(BASE_URI + '/subscriptions/funding/stripe', payload).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    )
  }

  addFundingSource(funding_type: string, subscription_id: string, token?: string, card_scheme?: string): Observable<FundingSource> {
    let payload: any = {};
    if (funding_type === 'card' && token && card_scheme) {

      // Checkout.com : to be deprecated
      payload.token = token;
      payload.card_scheme = card_scheme;
    } else if (funding_type === 'paypal_subscription') {
      payload = null;
    }

    return this.http.post<any>(BASE_URI + '/subscriptions/funding/' + funding_type, payload)
      .pipe(
        tap(data => {
          console.log('tapped addFundingSource response: ', data);
        }),
        map(data => {
          return data.data;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      )
  }

  verifyFundingSource(gateway: string, subscription_id: string, token?: string): Observable<FundingSource> {
    // console.log('verifyFundingSource', gateway, subscription_id, token);
    let payload: any = {};
    let verification_url: string;
    if ((gateway === 'checkout') && token) {
      verification_url = '/subscriptions/funding/card';
      payload.token = token;
    } else if (gateway === 'stripe') {
      verification_url = '/subscriptions/funding/stripe';
      payload.token = token;
    } else if (gateway === 'paypal' && subscription_id) {
      verification_url = '/subscriptions/funding/paypal_subscription';
      payload.subscription_id = subscription_id;
    }

    console.log('verifyFundingSource gateway payload:', gateway, payload);

    return this.http.post<any>(BASE_URI + verification_url, payload)
      .pipe(
        tap(data => {
          console.log('tapped verifyFundingSource response', data);
        }),
        map(data => {
          return data.data;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      )
  }

  cancelSubscription(): Observable<MemberSubscription> {
    return this.http.post<any>(BASE_URI + '/subscriptions/cancel', null)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      )
  }

  //////////////////////////////////////////////// $$

  getSiteConfig(): Observable<SiteConfiguration> {
    return this.http.get<any>(BASE_URI + '/config').pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    )
  }

  getCountries(): Observable<Country[]> {
    return this.http.get<any>(BASE_URI + '/countries?skip_loading').pipe(
      tap(data => {
        // Put US and GB at the top of the list.
        data.data.forEach((country: Country, i) => {
          if (country.code === 'US' || country.code === 'GB') {
            data.data.unshift(data.data.splice(i, 1)[0]);
          }
        });
      }),
      map(data => {
        return data.data;
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  getCountryNameByCode(countries: Country[], code: string): string {
    let _cname: string;
    countries.forEach(c => {
      if (c.code === code) {
        _cname = c.name;
      }
    });
    return _cname;
  }

  getCountryCodeByName(countries: Country[], name: string): string {
    let _ccode: string;
    countries.forEach(c => {
      if (c.name === name) {
        _ccode = c.code;
      }
    });
    return _ccode;
  }

  // See: New API : https://git.amazing-media.com/misc/geoip 
  // Currently being proxied through the existing endpoint.
  geoDataLookup(): Observable<GeoData> {
    return this.http.get<GeoData>(BASE_URI + '/geoip/lookup')
      .pipe(
        // tap(data => {
        //   console.log('GEO', data)
        // }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      )
  }

  newUser(data: any): Observable<{ data: UserV2, included: any }> {
    //
    let new_user = {
      type: 'user',
      attributes: data
    }
    // console.log('newUser data:', new_user);
    return this.http.post<any>(BASE_URI + '/users', { data: new_user })
      .pipe(
        // tap(data => {
        //   console.log('newUser response tap: ', data)
        // }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      )
  }

  newArtist(data: any): Observable<UserV2> {
    // console.log('newArtist:', data);
    let new_artist = {
      type: 'user',
      attributes: data
    }
    return this.http.post<any>(BASE_URI + '/users/' + this.userService.get().attributes.permalink + '/artists', { data: new_artist })
      .pipe(
        map(data => {
          return data.data;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      )
  }

  updateArtist(artist: UserV2, data_attributes: any): Observable<UserV2> {
    // console.log('updateArtist:', artist);
    let data = {
      id: artist.id,
      type: 'user',
      attributes: data_attributes
    }
    return this.http.patch<any>(BASE_URI + '/users/' + artist.attributes.permalink, { data: data })
      .pipe(
        map(data => {
          return data.data;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      )
  }


  getLabelArtistData(permalink_in_label: string, skip_loading?: boolean): Observable<UserV2> {
    // Only the managing label user should do this.
    // console.log('getLabelArtistData', permalink_in_label);
    let params_data = new HttpParams();
    if (skip_loading) {
      params_data = params_data.set('skip_loading', 'true');
    }
    params_data = params_data.set('include[]', 'user_private');
    params_data = params_data.append('include[]', 'user_stats');
    params_data = params_data.append('include[]', 'image');
    params_data = params_data.append('include[]', 'header_image');
    params_data = params_data.append('include[]', 'tunes');
    params_data = params_data.append('include[]', 'manager');
    params_data = params_data.append('include[]', 'subscription');

    // GET /users/:permalink/artists/:permalink_in_label
    return this.http.get<any>(BASE_URI + '/users/' + this.userService.get().attributes.permalink + '/artists/' + permalink_in_label, { params: params_data })
      .pipe(
        // tap(data => {
        //   console.log('Label Artist: ', data);
        // }),
        map(data => {
          let artist: UserV2 = data.data;
          let tunes = [];
          let collections = [];
          data.included.forEach(inc => {
            inc.attributes.id = inc.id;
            // Sort through the included data arrays
            switch (inc.type) {
              case 'collection':
                inc.tunes = [];
                inc.artist = {};
                //console.log('COLLECTION ID: ', inc.id);
                collections.push(inc);
                break;
              case 'tune':
                //console.log('TUNE ID: ', inc.id);
                tunes.push(inc);
                break;
              case 'image':
                if (inc.attributes.classification === 'user_image') {
                  artist._image = inc.attributes; // Used for logged-in user image management.
                  // console.log('%cPROFILE IMAGE', 'color:lime', _image);  
                } else if (inc.attributes.classification === 'user_header_image') {
                  artist._header_image = inc.attributes; // Used for logged-in user image management.
                  artist.attributes.header_image_urls = artist._header_image.urls;
                  // console.log('%cHEADER IMAGE', 'color:hotpink', _header_image);
                }
                break;
              case 'user_private':
                artist.user_private = inc.attributes;
                break;
              case 'user_stats':
                artist.user_stats = inc.attributes;
                // console.log('Got artist user_stats: ', artist.user_stats);
                // Create a 'collections' value for simplicity.
                artist.user_stats.upload_counts.collections = artist.user_stats.upload_counts.albums + artist.user_stats.upload_counts.eps + artist.user_stats.upload_counts.singles;
                break;
              case 'subscription':
                artist._subscriptions = [inc];
                artist = this.setHasCredits(artist);
                break;
              case 'user': // manager
                artist._manager = inc.attributes;
                if (artist._manager.id === this.userService.getId()) {
                  artist._is_manager = true;
                }
                break;
              default:
            }
          });
          artist.tunes = tunes;
          return artist;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      )
  }

  checkPermalink(username: string, is_label_artist: boolean = false): Observable<PermalinkValidityResponse> {
    const payload = is_label_artist ? { permalink_in_label: username } : { permalink: username };
    return this.http.post<any>(BASE_URI + '/users/check_permalink', payload)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      )
  }

  updateUser(user: UserV2, data_attributes?: any, data_relationships?: any): Observable<UserV2> {
    // console.log('update user: ', user.id, data_attributes);
    let data: UserV2 = {
      id: user.id,
      type: 'user',
    }
    if (data_attributes) {
      data.attributes = data_attributes;
    }
    if (data_relationships) {
      data.relationships = data_relationships;
    }

    // logged in user
    const this_user = this.userService.get();
    // console.log('PATCH data: ', data);
    return this.http.patch<any>(BASE_URI + '/users/' + user.attributes.permalink, { data: data })
      .pipe(
        // tap(response => {
        //   console.log('updateUser PATCH response: ', response);
        // }),
        map(data => {
          return data.data;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  updateImage(resource: any, image?: File, cropRect?: CropRect, type?: string): Observable<ImageV2> {

    // console.log('updateImage: ', resource, image, cropRect);
    let formData: FormData = new FormData();
    if (image !== null) {
      formData.append('image', image, image.name);
      //console.log('Sending Image:', formData.get('image'));
    } else {
      // console.log('NO NEW FILE.. must be a crop');
    }
    formData.append('data[type]', 'image');
    //formData.append('data[caption]', 'test caption');
    if (cropRect !== null) {
      const imageData = {
        attributes: {
          crop: {  // Effectively converts from CropRect (from the cropper) to CropRectData (used by the API) - maybe do this in the component..
            x: cropRect.x1,
            y: cropRect.y1,
            w: cropRect.x2 - cropRect.x1,
            h: cropRect.y2 - cropRect.y1,
          },
          caption: ''
        }
      };
      // console.log('Sending imageData.attributes.crop: ', imageData.attributes.crop);
      // data had to be done like this!
      formData.append('data[attributes][caption]', imageData.attributes.caption);
      formData.append('data[attributes][crop][x]', imageData.attributes.crop.x.toString());
      formData.append('data[attributes][crop][y]', imageData.attributes.crop.y.toString());
      formData.append('data[attributes][crop][w]', imageData.attributes.crop.w.toString());
      formData.append('data[attributes][crop][h]', imageData.attributes.crop.h.toString());
    }

    let endpoint_resource: string;
    switch (resource.type) {
      case 'user':
        endpoint_resource = '/users/' + resource.attributes.permalink + '/image';
        if (type === 'header') {
          endpoint_resource += '/header';
        }
        break;
      case 'tune':
        endpoint_resource = '/tunes/' + resource.id + '/image';
        break;
      case 'playlist':
        endpoint_resource = '/playlists/' + resource.id + '/image';
        break;
      case 'collection':
        endpoint_resource = '/collections/' + resource.id + '/image';
        break;
      default:
    }

    const req = new HttpRequest('POST', BASE_URI + endpoint_resource, formData);
    // console.log('updateImage request: ', req);
    return this.http.request<ImageV2>(req)
      .pipe(
        // tap(response => {
        //   console.log('updateImage response: ', response);
        // }),
        mergeMap(response => this.getImageData(resource, type)),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  getImageData(resource: any, type?: string): Observable<ImageV2> {
    let endpoint_resource: string;
    switch (resource.type) {
      case 'user':
        endpoint_resource = '/users/' + resource.attributes.permalink + '/image';
        if (type === 'header') {
          endpoint_resource += '/header';
        }
        break;
      case 'tune':
        endpoint_resource = '/tunes/' + resource.id + '/image';
        break;
      case 'playlist':
        endpoint_resource = '/playlists/' + resource.id + '/image';
        break;
      case 'collection':
        endpoint_resource = '/collections/' + resource.id + '/image';
        break;
      default:
    }
    // console.log('GET image data: ', BASE_URI + endpoint_resource);
    return this.http.get<ImageV2Response>(BASE_URI + endpoint_resource)
      .pipe(
        // tap(response => {
        //   console.log('image data: for ' + resource.type, response);
        // }),
        map(response => {
          // return as ImageV2
          if (response.data) {
            return response.data.attributes;
          } else {
            // console.log('no image data.attributes returned for ' + resource.type + ' - id:' + resource.id);
            return null;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      )
  }

  updateTune(id: string, payload: any): Observable<TuneV2> {
    return this.http.patch<any>(BASE_URI + '/tunes/' + id, { data: payload })
      .pipe(
        // tap(response => {
        //   console.log('PATCH TUNE response: ', response);
        // }),
        map(data => {
          return data.data;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  createTune(payload: any): Observable<TuneV2> {
    return this.http.post<any>(BASE_URI + '/tunes', { data: payload })
      .pipe(
        // tap(response => {
        //   console.log('CREATE NEW TUNE response: ', response);
        // }),
        map(data => {
          return data.data;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  deleteTune(tune: TuneV2): Observable<any> {
    return this.http.delete<any>(BASE_URI + '/tunes/' + tune.id)
      .pipe(
        // tap(response => {
        //   console.log('DELETE TUNE response: ', response);
        // }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  updateCollection(id: string, payload: any): Observable<CollectionV2> {
    return this.http.patch<any>(BASE_URI + '/collections/' + id, { data: payload })
      .pipe(
        // tap(response => {
        //   console.log('PATCH COLLECTION response: ', response);
        // }),
        map(data => {
          return data.data;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  createCollection(payload: any): Observable<CollectionV2> {
    return this.http.post<any>(BASE_URI + '/collections', { data: payload })
      .pipe(
        // tap(response => {
        //   console.log('CREATE NEW COLLECTION response: ', response);
        // }),
        map(data => {
          return data.data;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  deleteCollection(collection: CollectionV2): Observable<any> {
    return this.http.delete<any>(BASE_URI + '/collections/' + collection.id)
      .pipe(
        // tap(response => {
        //   console.log('DELETE Collections response: ', response);
        // }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  updatePlaylist(id: string, payload: any): Observable<PlaylistV2> {
    return this.http.patch<any>(BASE_URI + '/playlists/' + id, { data: payload })
      .pipe(
        // tap(response => {
        //   console.log('PATCH PLAYLIST response: ', response);
        // }),
        map(data => {
          return data.data;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }
  // For when ready
  deletePlaylist(playlist: PlaylistV2): Observable<any> {
    return this.http.delete<any>(BASE_URI + '/playlists/' + playlist.id)
      .pipe(
        // tap(response => {
        //   console.log('DELETE PLAYLIST response: ', response);
        // }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  pingTunePlay(tune_id: string): Observable<any> {
    origin = environment.site_url;
    return this.http.post<any>(BASE_URI + '/tunes/' + tune_id + '/played', { origin: origin })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  pingExternalVideoPlay(video_id: string): Observable<any> {
    origin = environment.site_url;
    return this.http.post<any>(BASE_URI + '/external_videos/' + video_id + '/played', { origin: origin })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  pingVideoPlay(video_id: string): Observable<any> {
    origin = environment.site_url;
    return this.http.post<any>(BASE_URI + '/videos/' + video_id + '/played', { origin: origin })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  getRadioChannel(radio_channel: string = environment.radio_channel): Observable<RadioChannel> {
    // The main site radio_channel can be overidden with a genre_stream 'radio_station'.
    let params_data = new HttpParams();
    params_data = params_data.set('include[]', 'playlists');
    params_data = params_data.set('skip_loading', 'true');
    return this.http.get<any>(BASE_URI + '/radio/' + radio_channel, { params: params_data })
      .pipe(
        tap(data => {
          // Build data for a/b/c playlists etc.
          if (data?.included) {
            data.included.map(_inc => {
              let _key = _inc.meta?.display_title?.toLowerCase().split(' ')[0];
              if (_key == 'a-list') _key = '';
              let _path = '/playlist/' + _key;
              _inc.key = _key;
              _inc.path = _path;
              _inc.title = _inc.meta.display_title.split(' ')[0];
            });
          }

        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  getGenreStream(station: string): Observable<GenreStream> {
    // The main site radio_channel can be overidden with a genre_stream 'radio_station'.
    let params_data = new HttpParams();
    params_data = params_data.set('skip_loading', 'true');
    return this.http.get<any>(BASE_URI + '/radio/' + station, { params: params_data })
      .pipe(
        map(data => {
          return data.data;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  getNowPlayingTune(radio_station: string = environment.radio_channel): Observable<RadioLogEntry> {
    // RadioLogEntry
    // :radio_station can be uk, us ('radio_channel') or any genre 'radio_station'
    let params_data = new HttpParams();
    params_data = params_data.set('include[]', 'tune');
    params_data = params_data.set('include[]', 'tune.artist');
    params_data = params_data.set('skip_loading', 'true');
    if (this.timeSlipService.offset > 0) {
      params_data = params_data.set('offset', this.timeSlipService.offset.toString());
    }
    return this.http.get<any>(BASE_URI + '/radio/' + radio_station + '/now_playing', { params: params_data })
      .pipe(
        map(data => {
          const _tune: TuneV2 = Object.assign({}, this.extractTuneAndArtistFromIncludedByTuneId(data.data.relationships.tune.data.id, data.included));
          data.data._tune = _tune;
          return data.data;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  getGenreStreams(skip_loading?: boolean): Observable<GenreStreams> {
    let params_data = new HttpParams();
    params_data = params_data.set('include[]', 'current_log_item.tune');
    params_data = params_data.append('include[]', 'current_log_item.tune.artist');

    if (skip_loading) {
      params_data = params_data.set('skip_loading', 'true');
    }

    return this.http.get<GenreStreams>(BASE_URI + '/radio/genre_streams', { params: params_data })
      .pipe(
        tap(data => {

          data.data.forEach(g_stream => {
            let current_log_item_id: string = g_stream.relationships.current_log_item?.data?.id;
            let _tune: TuneV2;
            // extract tunes from included for each stream...
            data.included.forEach(inc => {
              if (inc.id === current_log_item_id) {
                let tune_id: string = inc.relationships.tune.data.id;
                data.included.forEach(_inc => {
                  if (_inc.id === tune_id) {
                    _tune = _inc;
                    _tune.artist = this.extractUserFromTarget(_tune.relationships.artist.data.id, data.included);
                  }
                });
              }
            });
            if (_tune) {
              g_stream._current_tune = _tune;
            }
          });
          delete data.included;
          // console.log('%cGenre Streams', 'color:cyan',data);
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      )
  }

  // Radio History: API V2
  getRadioHistoryV2(before: number = 0, skip_loading: boolean = false, count: number = 16, radio_channel: string = environment.radio_channel): Observable<TuneV2[]> {
    // path  : /radio/uk/airplays
    // eg    : https://api.amazingtunes.mobi/radio/uk/airplays?include[]=tune&include[]=tune.artist
    // Docs  : https://amazingtunes.mobi/docs/api/v2/apiradio/airplays.html
    let params_data = new HttpParams();
    params_data = params_data.set('page[size]', count.toString());
    params_data = params_data.set('include[]', 'tune');
    params_data = params_data.append('include[]', 'tune.artist');
    if (skip_loading) {
      // Skips loading indicator for initial load. Will show it when paginating on the /aired page.
      params_data = params_data.set('skip_loading', 'true');
    }

    if (before > 0 || this.timeSlipService.offset > 0) {
      if (this.timeSlipService.offset > 0) {
        // add the offset minutes (as seconds)
        if (before < 1) {
          before = Math.round(Date.now() / 1000);
        }
        before = before - (this.timeSlipService.offset * 60);
      }
      params_data = params_data.set('filter[time][before]', before.toString());
    }

    return this.http.get<AirplaysV2>(BASE_URI + '/radio/' + radio_channel + '/airplays', { params: params_data })
      .pipe(
        map(data => {
          // console.log('AIRPLAY DATA', radio_channel, data);
          let tunes: TuneV2[] = [];
          data.data.forEach((item, i) => {
            //console.log('tune: id:', item.relationships.tune);
            if (item.relationships.tune.data) {
              // Extract the tune from included and clone it. This seems to deal with any strangeness in how the data is modified then stored in the new array when duplicates occur eg: after a Myriad hiccup.
              const _tune: TuneV2 = Object.assign({}, this.extractTuneAndArtistFromIncludedByTuneId(item.relationships.tune.data.id, data.included));
              // Custom internal properties..
              _tune._last_aired_at = item.attributes.aired_at;
              _tune.attributes.name = _tune.attributes.name;
              _tune._unique_id = 'radio-history_' + before + '_' + i;

              tunes.push(_tune);
            } else {
              // console.log('%cAIRPLAY TUNE (relationships) DATA WAS NULL: ', 'color:orange;font-weight:bold', item);
            }
          });
          // console.log('Airplays: ', tunes);
          return tunes;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  getFirstSpinTunes(before: number = 0, skip_loading: boolean = false, count: number = 16): Observable<{ nextPage: number, tunes: TuneV2[] }> {
    // The before date for this is now the `first_spin_at` date, not the `uploaded_at` date!!
    let params_data = new HttpParams();
    params_data = params_data.set('page[size]', count.toString());
    params_data = params_data.append('include[]', 'artist');
    if (skip_loading) {
      params_data = params_data.set('skip_loading', 'true');
    }
    if (before > 0) {
      params_data = params_data.set('filter[time][before]', before.toString());
    }

    // Genre filter ? ... hmm. nope.
    // params_data = params_data.set('filter[primary_genre]', '1016');

    return this.http.get<any>(BASE_URI + '/tunes', { params: params_data })
      .pipe(
        map(data => {
          let tunes: TuneV2[] = [];
          data.data.forEach((item, i) => {
            let _tune: TuneV2 = item;
            data.included.forEach(_inc => {
              if (_inc.type === 'user' && _inc.id === _tune.relationships.artist.data.id) {
                _tune.artist = _inc;
                if (_tune.artist.id === this.userService.getId()) {
                  _tune._is_owner = true;
                }
                if (_tune.artist.relationships.manager && _tune.artist.relationships.manager.data.id === this.userService.getId()) {
                  _tune.artist._is_manager = true;
                  _tune._is_manager = true;
                }
              }
              _tune._unique_id = 'first-spin_' + before + '_' + i;
            });
            tunes.push(_tune);
          });
          let next_page;
          if (data.meta.pagination.page < data.meta.pagination.total_pages) {
            next_page = data.meta.pagination.page + 1;
          }
          // console.log('First Spins', tunes, next_page);
          return {
            nextPage: next_page,
            tunes: tunes
          };
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  getLatestTunes(page: number = 0, per_page: number = 20, skip_loading: boolean = false,): Observable<{ nextPage: number, tunes: TuneV2[] }> {
    // The before date for this is now the `first_spin_at` date, not the `uploaded_at` date!!
    let params_data = new HttpParams();
    params_data = params_data.set('page[number]', page.toString());
    params_data = params_data.set('page[size]', per_page.toString());
    params_data = params_data.append('include[]', 'artist');
    if (skip_loading) {
      params_data = params_data.set('skip_loading', 'true');
    }
    return this.http.get<any>(BASE_URI + '/tunes/latest', { params: params_data })
      .pipe(
        map(data => {
          let tunes: TuneV2[] = [];
          data.data.forEach((item: TuneV2) => {
            let _tune: TuneV2 = item;
            data.included.forEach((_inc: any) => {
              if (_inc.type === 'user' && _inc.id === _tune.relationships.artist.data.id) {
                _tune.artist = _inc;
                if (_tune.artist.id === this.userService.getId()) {
                  _tune._is_owner = true;
                }
                if (_tune.artist.relationships.manager && _tune.artist.relationships.manager.data.id === this.userService.getId()) {
                  _tune.artist._is_manager = true;
                  _tune._is_manager = true;
                }
              }
            });
            tunes.push(_tune);
          });
          let next_page: any;
          if (data.meta.pagination.page < data.meta.pagination.total_pages) {
            next_page = data.meta.pagination.page + 1;
          }
          // console.log('Latest Tunes', tunes, next_page);
          return {
            nextPage: next_page,
            tunes: tunes
          };
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  private extractTuneAndArtistFromIncludedByTuneId(tune_id: string, included: any[]): TuneV2 {
    // Pull out a Tune (and its Artist) from a list of included data, given tune_id.
    let _tune: TuneV2;
    included.forEach(_inc => {
      if (_inc.type === 'tune' && _inc.id === tune_id) {
        _tune = _inc;
      }
    });
    included.forEach(_inc => {
      // console.log('inc.ID', _inc?.id);
      // console.log('tune', _tune.relationships?.artist)

      if (_tune.relationships?.artist?.data && _inc.type === 'user' && _inc.id === _tune.relationships?.artist?.data?.id) {
        _tune.artist = _inc;
        // remove this now
        delete _tune.artist.relationships;
      }
    });
    return _tune;
  }

  // For uploads
  public getTuneUploadParams(): Observable<TuneUploadData> {
    return this.http.get<any>(BASE_URI + '/tunes/upload')
      .pipe(
        // tap(data => {
        //   console.log('getTuneParams: ', data);
        // }),
        map(data => {
          return data.data.attributes;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  // Called by interval after tune uploads
  public checkTransloaditUploadStatus(transloadit_url: string): Observable<TransloaditResponse> {
    return this.http.get<any>(transloadit_url);
  }

  // Voice Clips 
  // https://amazingtunes.mobi/docs/api/v2/apivoice_clips.html
  // POST /voice_clips/upload (pre-upload)
  // POST /voice_clips  (create)
  // GET /voice_clips
  // GET /voice_clips/:uuid
  // PATCH /voice_clips/:uuid
  // DELETE /voice_clips/:uuid

  public getVoiceClipUploadParams(): Observable<TuneUploadData> {
    return this.http.get<any>(BASE_URI + '/voice_clips/upload')
      .pipe(
        tap(data => {
          console.log('getVoiceClipUploadParams: ', data);
        }),
        map(data => {
          return data.data.attributes;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  public createVoiceClip(payload: any): Observable<VoiceClip> {
    return this.http.post<any>(BASE_URI + '/voice_clips', payload)
      .pipe(
        tap(response => {
          console.log('CREATE NEW VOICE CLIP response: ', response);
        }),
        map(data => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  public updateVoiceClip(id: string, payload: any): Observable<VoiceClip> {
    return this.http.patch<any>(BASE_URI + '/voice_clips/' + id, { data: payload })
      .pipe(
        tap(response => {
          console.log('PATCH VOICE CLIP response: ', response);
        }),
        map(data => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  public deleteVoiceClip(voice_clip: VoiceClip): Observable<any> {
    return this.http.delete<any>(BASE_URI + '/voice_clips/' + voice_clip.id)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  // Internal Analytics.

  // Triggered by payment dialogs when opened.
  trackInitiateCheckout(): Observable<any> {
    // return this.http.post<any>(BASE_URI + '/analytics/initiate-checkout', { plan_id: plan_id })
    return this.http.post<any>(BASE_URI + '/analytics/initiate-checkout', null)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  // User Activity Feed.
  getActivityFeed(before?: number, permalink?: string, exclude_self: boolean = true): Observable<ActivityFeed> {

    let params_data = new HttpParams();
    if (before > 0) {
      params_data = params_data.set('filter[time][before]', before.toString());
    }
    let path = '/activity';
    if (permalink) {
      path = '/users/' + permalink + '/activity';
    }
    params_data = params_data.set('include[]', 'actor');
    params_data = params_data.append('include[]', 'target');
    params_data = params_data.append('include[]', 'target.artist');
    params_data = params_data.append('include[]', 'target.user');
    // No need to show the user a list of their own actions by default.
    if (exclude_self) {
      params_data = params_data.set('exclude_self', 'true');
    }

    return this.http.get<ActivityFeed>(BASE_URI + path, { params: params_data }).pipe(
      map(data => {
        const logged_in_user_id: string = this.userService.getId();
        // Remove actions on/from null data objects. Likely deleted.
        data.data = data.data.filter(_d => {
          return _d.relationships.target.data !== null && _d.relationships.actor.data !== null;
        });
        data.data.forEach((_data, i) => {
          _data.relationships.actor.data = this.extractActivityInclude(_data.relationships.actor.data.id, data.included, logged_in_user_id);
          _data.relationships.target.data = this.extractActivityInclude(_data.relationships.target.data.id, data.included, logged_in_user_id);
        });
        delete data.included;
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }

  extractActivityInclude(id: string, included: any[], logged_in_user_id?: string): any {

    const collection_routings: any = {
      EP: 'eps',
      Single: 'singles',
      Album: 'albums'
    };

    let inc: any;
    included.forEach(_inc => {
      if (_inc.id === id) {
        inc = _inc;
        if (inc.type === 'tune' || inc.type === 'collection') {
          inc.artist = this.extractActivityInclude(inc.relationships.artist.data.id, included);
          if (inc.artist.id === logged_in_user_id) {
            inc.artist._is_owner = true;
          }
          if (inc.type === 'tune') {
            inc._unique_id = 'act_' + inc.id;
          } else
            if (inc.type === 'collection') {
              inc._routing = collection_routings[inc.attributes.type];
            }
        }
        else if (inc.type === 'external_video' || inc.type === 'video') {
          inc.artist = this.extractActivityInclude(inc.relationships.user.data.id, included);
          if (inc.artist.id === logged_in_user_id) {
            inc.artist._is_owner = true;
          }
        }
        else if (inc.type === 'playlist') {
          inc._curator = this.extractActivityInclude(inc.relationships.user.data.id, included);
          if (inc._curator.id === logged_in_user_id) {
            inc._curator._is_owner = true;
          }
        } else if (inc.type === 'user') {
          if (inc.id === logged_in_user_id) {
            inc._is_owner = true;
          }
        }
      }
    });
    return inc;
  }


  contentReport(report: ContentReport): Observable<ContentReport> {
    // console.log('Report payload: ', report);
    return this.http.post<any>(BASE_URI + '/reporting', report).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    )
  }

  // Notifications
  public registerNotification(token: string, show_uuid?: string): Observable<any> {
    let _channel = 'general';
    if (show_uuid) {
      _channel = 'radio_show/' + environment.site_country;
    }
    let args: any = {
      data: {
        type: 'notifications_subscription',
        attributes: {
          device_token: token,
          channel: _channel
        }
      }
    };
    if (show_uuid) {
      args.data.attributes.target = show_uuid;
    } else {
      args.data.attributes.target = '*';
    }

    return this.http.post<any>(BASE_URI + '/notifications', args)
      .pipe(
        // tap(data => {
        //   console.log('registerNotification:', data);
        // }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      )
  }

  public deleteNotification(token: string, show_uuid?: string): Observable<any> {
    let _channel = 'general';
    if (show_uuid) {
      _channel = 'radio_show/' + environment.site_country;
    }
    let params_data = new HttpParams();
    params_data = params_data.set('data[type]', 'notifications_subscription');
    params_data = params_data.set('data[attributes][device_token]', token);
    params_data = params_data.set('data[attributes][channel]', _channel);
    if (show_uuid) {
      params_data = params_data.set('data[attributes][target]', show_uuid);
    } else {
      params_data = params_data.set('data[attributes][target]', '*');
    }

    return this.http.delete<any>(BASE_URI + '/notifications', { params: params_data })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      )
  }

  public getNotifications(browser_token: string): Observable<any[]> {
    let params_data = new HttpParams();
    params_data = params_data.set('data[type]', 'notifications_subscription');
    params_data = params_data.set('data[attributes][device_token]', browser_token);
    return this.http.get<any>(BASE_URI + '/notifications', { params: params_data })
      .pipe(
        tap(data => {
          // Store. Fixes the ExpressionChangedAfterItHasBeenCheckedError 
          // from asynchronously calling the API just to show the bell state.
          // Also makes the bell more reusable.
          if (typeof window !== 'undefined') {
            localStorage.setItem('amazing-notifications', JSON.stringify(data));
            // console.log('Notifications stored:', data);
          }
        }),
        map(data => {
          return data.data;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  // Secure Tune streaming URL
  getTuneStreamingUrl(tune_id: string): Observable<TuneStream> {
    return this.http.get<any>(BASE_URI + '/stream/tune/' + tune_id).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      })
    );
  }


  // Common error handler
  private handleError(error: HttpErrorResponse) {
    if (isPlatformBrowser(this.platformId)) { // NOT SSR
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred.
        console.error('A client-side or network error occurred:', error.error.message);
        // return an observable with a user-facing error message
        // return throwError({ status: -1, message: error.error.message });
        return throwError(() => error.error)

      } else {
        // The backend returned an unsuccessful response code.
        console.log('handleError(): ERROR: ', error);

        // Use our custom error messages, as opposed to built-in ones such as "Http failure response for https://api.amazingtunes.mobi/users/thecollectorsba?include%5B%5D=user_stats&include%5B%5D=manager&include%5B%5D=user_private&include%5B%5D=subscription&include%5B%5D=header_image&ngsw-bypass=true: 404 OK"
        let error_message = error.message;
        if (error.error && error.error.errors?.length) {
          error_message = error.error.errors[0].title;
        }
        let _error: any = { status: error.status, message: error_message };
        if (error.error && error.error.data) {
          _error.data = error.error.data;
        }

        if (error.status === 401) {
          console.log('AUTH ERROR');
          this.userService.logOut(true)
        }

        // After the V16 upgrade, this will need to have this signature
        return throwError(() => error)

      }
    } else {
      console.log('API V2 ERROR: ', error);
      // let error_message = error.message;
      // if (error.error && error.error.errors) {
      //   error_message = error.error.errors[0].title;
      // }
      // let _error: any = { status: error.status, message: error_message };
      // if (error.error && error.error.data) {
      //   _error.data = error.error.data;
      // }

      // return throwError(error);
      return throwError(() => error)

    }
  };

}
