<div id="player" [class]="is_iphonex ? 'iphonex' : ''">
  <div class="innerContent playerCont">
    <div *ngIf="tune" class="player">
      <div class="control-btns" [class]="player.playlist.length > 1 || is_mp3 ? 'extras':''">
        <!-- previous playlist tune -->
        <mat-icon role="button" *ngIf="player.playlist.length > 1" svgIcon="skip_previous" title="Play Previous Tune" class="previous" (click)="player.playPreviousTune()"></mat-icon>
        <!-- Skip back 30 seconds - only MP3 longplays/rewinds/etc. -->
        <mat-icon role="button" class="previous" title="Skip back 30 seconds" (click)="player.skipBack30()" [class]="player.audio.currentTime < 30 ? 'disabled':''" *ngIf="is_mp3">replay_30</mat-icon>
        <!-- main play/pause button -->
        <div class="play-btn-cont">
          <mat-icon title="Playlists will play in reverse" class="tiny-reverse-icon" *ngIf="playlist_reverse && player.playlist.length > 1">settings_backup_restore</mat-icon>
          <mat-icon role="button" (click)="player.toggleAudio()" title="Play" [class]="icon_class[stateIndex]" [svgIcon]="svg_icons[stateIndex]"></mat-icon>
        </div>
        <!-- next playlist tune -->
        <mat-icon role="button" *ngIf="player.playlist.length > 1" svgIcon="skip_next" id="btn_next" title="Play Next Tune" class="next" (click)="player.playNextTune()"></mat-icon>
        <!-- Skip forward 30 seconds - only MP3 longplays/rewinds/etc. -->
        <mat-icon role="button" class="next" title="Skip formward 30 seconds" (click)="player.skipForward30()" [class]="player.audio.currentTime + 30 > duration_secs ? 'disabled':''" *ngIf="is_mp3">forward_30</mat-icon>
      </div>

      <div class="time-slip-cont" *ngIf="timeSlipService.offset > 0" title="Time-Slipped Playback">
        <mat-icon class="time-slip">history</mat-icon>
      </div>

      <!-- Image from current show if hide_now_playing is true AND nothing else has been requested to play! -->
      <img routerLink="/onair" *ngIf="player?.current_show?.show.hide_now_playing && !player.rewinds && !player.genre_stream && player.currentTune === null; else mainImage" aria-hidden="true" class="artwork" [src]="player.current_show.square_300_image" src="/assets/placeholders/placeholder-square-300.jpg">
      <!-- rewind sync playlist tune image -->
      <a *ngIf="player.rewinds && player.rewind_tunes.length > 0;else rewindDefaultImage" aria-hidden="true" [routerLink]="['/profile/', player.rewind_tunes[player.rewind_index].artist.attributes.permalink, 'tunes', player.rewind_tunes[player.rewind_index].id]"><img class="artwork" [lazyImgSrc]="player.rewind_tunes[player.rewind_index].attributes.image_urls.medium" src="/assets/placeholders/placeholder-square-300.jpg" /></a>
      <ng-template #rewindDefaultImage>
        <!-- default rewind show image -->
        <a aria-hidden="true" *ngIf="player.rewinds" title="hello" [routerLink]="tune._show_path"><img *ngIf="tune" class="artwork" [lazyImgSrc]="tune.attributes.image_urls.medium" src="/assets/placeholders/placeholder-square-300.jpg" /></a>
      </ng-template>
      <ng-template #mainImage>
        <!-- main tune image : on-demand and radio/gentre streams -->
        <img aria-hidden="true" *ngIf="tune && !player.rewinds && !streaming_custom" class="artwork" [routerLink]="featured ? null : ['/profile',tune.artist.attributes.permalink, 'tunes', tune.id]" [lazyImgSrc]="tune.attributes.image_urls.medium" src="/assets/placeholders/placeholder-square-300.jpg" [class]="timeSlipService.offset == 0 ? '':'has-time-slip'" />
      </ng-template>

      <!-- tune details -->
      <div class="playerInfo" [class]="timeSlipService.offset == 0 ? '':'has-time-slip'">
      <!-- Player header labels : Current Show / Stream, etc. -->
        <!-- Current on air radio show -->
        <div *ngIf="!player?.playlistData?.id && !player.genre_stream && !player.rewinds && !player.playlist.length && !featured && !streaming_custom && player?.current_show?.id && player.streaming && !player?.current_show?.show.hide_now_playing" class="player-top-header elipsify" routerLink="/onair"><span>{{ timeSlipService.offset > 0 ? ('Time-Slipped : ' + timeSlipService.offset_str):'On Air' }} :</span> {{ player?.current_show?.show.name }}</div>
        <div *ngIf="!player?.current_show?.id" class="player-top-header elipsify">Now Playing</div>
        <!-- genre streams -->
        <div *ngIf="player.genre_stream" [routerLink]="['/genre-streams', player.genre_stream.id]" class="player-top-header elipsify"><span>Genre Stream :</span>{{player.genre_stream.attributes.name}}</div>
        <!-- rewinds (mp3) -->
        <div *ngIf="!player?.playlistData?.id && player.rewinds" class="player-top-header elipsify" [routerLink]="[player.current_rewind.show_route, 'archive', player.current_rewind.airing_id]"><span>Rewind : </span>{{ player.current_rewind.display_artist + (player.current_rewind.display_title ? ' - ' + player.current_rewind.display_title:'') }}</div>
        <!-- (show) playlists (rewind) -->
        <div *ngIf="player?.playlistData?.type === 'playlist' || player?.playlistData?.type === 'chart' || player?.playlistData?.type === 'collection'"
           [routerLink]="player.playlistData._router_link ? player.playlistData._router_link : player.playlistData._curator ? ['/profile', player.playlistData._curator.attributes.permalink, 'playlists', player.playlistData.id] :null"
           class="player-top-header elipsify"><span [innerHTML]="(player.playlistData.id === 'rewind' ? 'Rewind' : player?.playlistData?.type === 'collection' ? collections_type[player?.playlistData.attributes.type]:player?.playlistData?.type ) + ' : '"></span>{{ player.playlistData.attributes.name }}</div>
        <!-- // End player header labels -->

        <!-- Main now playing info -->
        <div *ngIf="streaming">
          <div *ngIf="!player?.current_show?.show.hide_now_playing || player.genre_stream; else hideNowPlaying">
            <h3 class="elipsify radio name">
              <a role="link" [routerLink]="featured ? '#' : '/profile/' + tune.artist.attributes.permalink" [innerHTML]="tune.artist.attributes.name" [title]="tune.artist.attributes.name"></a>
              <img class="flag" *ngIf="tune.artist.attributes.country" [title]="cs.getCountryNameByCode(tune.artist.attributes.country)" [src]="'/assets/flags/'+tune.artist.attributes.country.toLowerCase()+'.png'">
            </h3>
            <h5 class="elipsify radio title">
              <a role="link" [routerLink]="featured ? null : '/profile/' + tune.artist.attributes.permalink + '/tunes/' + tune.id" [title]="tune.attributes.name" [innerHTML]="tune.attributes.name"></a>
            </h5>
          </div>
        </div>

        <ng-template #hideNowPlaying>
          <!-- Display the Show details only, eg: AOTW/ROTW -->
          <h3 class="elipsify radio name">
            <a role="link" [innerHTML]="player?.current_show?.show.name" [routerLink]="['/shows', player?.current_show?.show.id]"></a>
          </h3>
          <h5 class="elipsify radio title caps" *ngIf="player.current_show.presenter_covering || player.current_show.show.presenter !== player.current_show.show.name">
            <a role="link" [innerHTML]="player?.current_show.presenter" [routerLink]="['/shows', player?.current_show?.show.id]"></a>
          </h5>
        </ng-template>

        <!-- on demand tune player info -->
        <div *ngIf="!streaming && !streaming_custom">
          <!-- uid: {{ player.unique_id }} -->
          <h3 *ngIf="tune && !player.rewinds; else playerTextOverride" class="tune elipsify">
            <img class="flag on-demand" *ngIf="tune.artist.attributes.country" [title]="cs.getCountryNameByCode(tune.artist.attributes.country)" [src]="'/assets/flags/'+tune.artist.attributes.country.toLowerCase()+'.png'">
            <span aria-hidden="true" class="position" *ngIf="tune.chart">#{{ tune.chart.position }} </span>
            <a class="name" [routerLink]="featured ? '#' : '/profile/' + tune.artist.attributes.permalink" [innerHTML]="tune.artist.attributes.name" [title]="tune.artist.attributes.name"></a>
            <span aria-hidden="true" *ngIf="!featured"> - </span>
            <a class="title" [routerLink]="featured ? null : tune.attributes.is_private ? null : '/profile/' + tune.artist.attributes.permalink + '/tunes/' + tune.id" [title]="tune.attributes.name" [innerHTML]="tune.attributes.name"></a>
          </h3>
        </div>

        <ng-template #playerTextOverride>
          <!-- This is the text that will be (briefly) displayed when no tune or data is present at all. ie: when loading the first time,
            until the now playing tune data arrives or a tune is loaded.
            Also now used for Rewinds, where tune.locations.html is the local show page path. -->
          <h3 *ngIf="player.rewinds && player.rewind_tunes.length > 0; else rewindShow" class="tune elipsify">
            <img class="flag on-demand override" *ngIf="player.rewind_tunes[player.rewind_index].artist.attributes.country" [title]="cs.getCountryNameByCode(player.rewind_tunes[player.rewind_index].artist.attributes.country)" [src]="'/assets/flags/'+player.rewind_tunes[player.rewind_index].artist.attributes.country.toLowerCase()+'.png'">
            <a class="name" [routerLink]="['/profile/', player.rewind_tunes[player.rewind_index].artist.attributes.permalink]" [innerHTML]="player.rewind_tunes[player.rewind_index].artist.attributes.name"></a>
            <span aria-hidden="true"> - </span>
            <a class="title" [routerLink]="['/profile/', player.rewind_tunes[player.rewind_index].artist.attributes.permalink, 'tunes', player.rewind_tunes[player.rewind_index].id]" [innerHTML]="player.rewind_tunes[player.rewind_index].attributes.name"></a>
          </h3>
        </ng-template>

        <ng-template #rewindShow>
          <a role="link" *ngIf="player.rewinds; else rewindTextOverride" [routerLink]="player.displayTune._show_path ? player.displayTune._show_path : '/'"><h3 id="defaultText" class="elipsify"  [innerHTML]="tune.artist.attributes.name + ' - ' + tune.attributes.name"></h3></a>
        </ng-template>

        <ng-template #rewindTextOverride>
          <h3 role="link" [routerLink]="player.playerTextOverride.routerLink" id="defaultText" class="elipsify">{{ player.playerTextOverride.artist }} - {{ player.playerTextOverride.title }}</h3>
        </ng-template>

        <!-- only display for non-streaming -->
        <div *ngIf="!streaming && !streaming_custom" class="playerSlider">
          <div aria-hidden="true" class="elapsedTime timer">{{ player.currentTime | secsToTime }}</div>
          <div aria-hidden="true" class="slider">
            <app-slider [value]="progress / 100" (sliderEvent)="player.sliderEvent($event)"></app-slider>
          </div>
          <div [attr.aria-label]="'Duration ' + (duration_secs | secsToTime)" class="totalTime timer">{{ duration_secs | secsToTime }}</div>
        </div>
      </div>

      <!-- options menu -->
      <div role="button" *ngIf="!tune.attributes.is_private && !player?.current_show?.show.hide_now_playing" [matMenuTriggerFor]="menu" class="options" title="show options">
        <mat-icon aria-hidden="true" inline="true">more_vert</mat-icon>
      </div>

      <!-- volume control - should only show on medium and large viewports which are not tablets or phones -->
      <div aria-hidden="true" *ngIf="!player.is_mobile_safari" class="volumeControl">
        <mat-icon class="volumeIcon" (click)="player.volumeMute()" [innerHTML]="player.getVolume() === 0 ? 'volume_off':'volume_up'"></mat-icon>
        <div class="volumeSlider" title="Volume Control">
          <app-slider [value]="player.getVolume()" (sliderEvent)="player.volumeSliderEvent($event)"></app-slider>
        </div>
      </div>

      <mat-menu role="listbox" class="shareResourcePanel" #menu="matMenu">
        <button mat-menu-item>
          <app-action-button-like class="action" title="Like Tune" with_label="Like Tune" [resource]="tune"></app-action-button-like>
        </button>
        <button mat-menu-item>
          <app-action-button-playlist class="action" title="Add Tune to a Playlist" with_label="Add To Playlist" [tunes]="[tune]"></app-action-button-playlist>
        </button>
        <app-action-player-buttons [tune]="tune"></app-action-player-buttons>
        <button *ngIf="player.playlist.length > 1" mat-menu-item (click)="togglePlaylistReverse()" [class]="playlist_reverse ? 'reverse':''">
          <mat-icon>settings_backup_restore</mat-icon>
          <div class="label">{{ playlist_reverse ? 'NORMAL PLAYLIST PLAY':'REVERSE PLAYLIST PLAY' }}</div>
        </button>

      </mat-menu>

    </div>
  </div>
</div>
